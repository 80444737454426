/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useImperativeHandle } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  changeGenericConsent,
  saveAdditionalDataAsync,
  setSelectedBusinessRelationPurpose,
  setSelectedFundsOrigin,
} from '../../../providers/application/application.actions';
import { requiredTextField } from '../../../utils/validation.utils';
import CustomPopper from '../custom-popper/custom-popper.component';
import Info from '../../info/info.component';
import useStyles from './additional-data-step.styles';
import Constants from '../../../utils/constants';

const AdditionalDataStep = ({ formRef, backRef }) => {
  const { register, handleSubmit, errors, control, getValues } = useForm();
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    selectedBusinessRelationPurposes,
    isCashTransactionsBusinessRelationPurposeSelected,
    monthlyAmountCashTransactions,
    selectedFundsOrigin,
    isProvidedServicesFundsOriginSelected,
    providedServicesFundsOrigin,
    isForPoliticallyExposedPerson,
    isForPoliticallyExposedRelatedPerson,
    nonFinancialEntity,
    isActiveNonFinancialEntity,
    doBeneficialOwnersHaveForeignCitizenship,
    nomenclatures: {
      businessRelationPurposes,
      businessRelationPurposesOther,
      businessRelationPurposesCashTransactions,
      fundsOrigins,
      fundsOriginProvidedServices,
      amounts,
      nonFinancialEntities,
    },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  /* NOTE: We get radiogroup values from state, because in the submitted data they are strings */
  const constructFormData = (data) => {
    return {
      ...data,
      selectedBusinessRelationPurposesIds: data.selectedBusinessRelationPurposes.map((bs) => bs.id),
      selectedFundsOriginIds: data.selectedFundsOrigin.map((bs) => bs.id),
      isActiveNonFinancialEntity:
        isActiveNonFinancialEntity !== '' ? isActiveNonFinancialEntity : null,
      isForPoliticallyExposedPerson:
        isForPoliticallyExposedPerson !== '' ? isForPoliticallyExposedPerson : null,
      isForPoliticallyExposedRelatedPerson:
        isForPoliticallyExposedRelatedPerson !== '' ? isForPoliticallyExposedRelatedPerson : null,
      doBeneficialOwnersHaveForeignCitizenship:
        doBeneficialOwnersHaveForeignCitizenship !== ''
          ? doBeneficialOwnersHaveForeignCitizenship
          : null,
    };
  };

  const onSubmit = (data) => {
    saveAdditionalDataAsync(constructFormData(data), dispatch);
  };

  const handleChange = (e) => {
    dispatch(changeGenericConsent({ [e.target.name]: e.target.value === 'true' }));
  };

  const handleBusinessRelationPurposesChange = (value) => {
    const isOtherSelected = value.some((item) => item.id === businessRelationPurposesOther);
    const isCashTransactionsSelected = value.some(
      (item) => item.id === businessRelationPurposesCashTransactions
    );
    let data = {
      isCashTransactionsBusinessRelationPurposeSelected: isCashTransactionsSelected,
    };

    if (!isOtherSelected) {
      data = { ...data };
    }

    if (!isCashTransactionsSelected) {
      data = { ...data, monthlyAmountCashTransactions: '' };
    }

    dispatch(setSelectedBusinessRelationPurpose(data));
  };

  const handleFundsOriginChange = (value) => {
    const isProvidedServicesSelected = value.some(
      (item) => item.id === fundsOriginProvidedServices
    );

    let data = {
      isProvidedServicesFundsOriginSelected: isProvidedServicesSelected,
    };

    if (!isProvidedServicesSelected) {
      data = { ...data, providedServicesFundsOrigin: '' };
    }

    dispatch(setSelectedFundsOrigin(data));
  };

  const handleNonFinancialEntityChange = (e) => {
    dispatch(
      changeGenericConsent({
        [e.target.name]: e.target.value === 'true',
        nonFinancialEntity: '',
      })
    );
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  useEffect(() => {
    handleBusinessRelationPurposesChange(selectedBusinessRelationPurposes);
    handleFundsOriginChange(selectedFundsOrigin);
  }, []);

  useEffect(() => {
    // HACK: React Hook Form is unable to automatically focus Autocomplete components on validation errors.
    if (errors && Object.keys(errors).length > 0) {
      const element = document.getElementsByClassName('Mui-error')[0];
      if (element) {
        const elementRect = element.getBoundingClientRect();
        window.scrollTo(0, elementRect.top + window.scrollY - window.innerHeight / 2);
      }
    }
  }, [errors]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container style={{ gap: '16px' }}>
        <Grid item xs={12} className={classes.boxContainer}>
          <div className={classes.subsectionLabel}>
            {t('additional-data.business-relation-purpose')}
          </div>
          <Grid container className={classes.inputContainer}>
            <Grid item xs={12}>
              <Controller
                name='selectedBusinessRelationPurposes'
                defaultValue={selectedBusinessRelationPurposes}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    multiple
                    value={props.value}
                    options={businessRelationPurposes}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => !!value.id && value.id === option.id}
                    onChange={(_, value) => {
                      handleBusinessRelationPurposesChange(value);
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('additional-data.business-relation-purpose-label')}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.selectedBusinessRelationPurposes}
                        helperText={
                          errors.selectedBusinessRelationPurposes &&
                          errors.selectedBusinessRelationPurposes.message
                        }
                        placeholder={t('general.add-data')}
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => value.length > 0 || t('validation.field.required'),
                }}
              />
            </Grid>
            {isCashTransactionsBusinessRelationPurposeSelected && (
              <Grid item xs={12}>
                <Controller
                  name='monthlyAmountCashTransactions'
                  defaultValue={monthlyAmountCashTransactions || ''}
                  rules={{
                    required: {
                      value: true,
                      message: t('validation.field.required'),
                    },
                  }}
                  render={(props) => (
                    <TextField
                      label={t('additional-data.amount-per-month')}
                      value={props.value}
                      select
                      error={!!errors.monthlyAmountCashTransactions}
                      helperText={
                        errors.monthlyAmountCashTransactions &&
                        errors.monthlyAmountCashTransactions.message
                      }
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                    >
                      {amounts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.boxContainer}>
          <div className={classes.subsectionLabel}>{t('additional-data.funds-origin')}</div>
          <Grid container className={classes.inputContainer}>
            <Grid item xs={12}>
              <Controller
                name='selectedFundsOrigin'
                defaultValue={selectedFundsOrigin}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    multiple
                    PopperComponent={CustomPopper}
                    value={props.value}
                    options={fundsOrigins}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => !!value.id && value.id === option.id}
                    onChange={(_, value) => {
                      handleFundsOriginChange(value);
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('general.funds-origin-label')}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.selectedFundsOrigin}
                        helperText={
                          errors.selectedFundsOrigin && errors.selectedFundsOrigin.message
                        }
                        placeholder={t('general.add-data')}
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => value.length > 0 || t('validation.field.required'),
                }}
              />
            </Grid>
            {isProvidedServicesFundsOriginSelected && (
              <Grid item xs={12}>
                <TextField
                  label={t('additional-data.funds-origin-provided-services-label')}
                  name='providedServicesFundsOrigin'
                  defaultValue={providedServicesFundsOrigin}
                  inputProps={{
                    maxLength: 50,
                  }}
                  error={!!errors.providedServicesFundsOrigin}
                  helperText={
                    errors.providedServicesFundsOrigin && errors.providedServicesFundsOrigin.message
                  }
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.boxContainer}>
          <span>{t('additional-data-step.status-for-non-financial-entity')}</span>
          <Info
            title={t('additional-data-step.non-financial-entity')}
            content={t('additional-data-step.non-financial-entity-info-text')}
            smallIcon
          />
          <RadioGroup name='isActiveNonFinancialEntity' value={isActiveNonFinancialEntity} row>
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isActiveNonFinancialEntity'
              value
              onClick={handleNonFinancialEntityChange}
              control={<Radio />}
              label={t('additional-data-step.non-financial-entity-active')}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isActiveNonFinancialEntity'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('additional-data-step.non-financial-entity-passive')}
            />
          </RadioGroup>
          {errors.isActiveNonFinancialEntity && (
            <p className='radioButtonsErrorMessage'>{errors.isActiveNonFinancialEntity.message}</p>
          )}
          {isActiveNonFinancialEntity && (
            <Grid item xs={12} className={classes.subsection}>
              <Controller
                name='nonFinancialEntity'
                defaultValue={nonFinancialEntity}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    value={props.value}
                    PopperComponent={CustomPopper}
                    options={nonFinancialEntities}
                    getOptionLabel={(option) =>
                      (!!option &&
                        (option.name ||
                          nonFinancialEntities.find((entity) => entity.id === option).name)) ||
                      ''
                    }
                    isOptionEqualToValue={(option, value) => !!value && value === option.id}
                    getOptionSelected={(option, value) => option.id === value}
                    onChange={(_, value) => props.onChange(value && value.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('additional-data-step.non-financial-entity-type')}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.nonFinancialEntity}
                        helperText={errors.nonFinancialEntity && errors.nonFinancialEntity.message}
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => !!value || t('validation.field.required'),
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.boxContainer}>
          <span>{t('additional-data-step.do-beneficial-owners-have-foreign-citizenship')}</span>
          <RadioGroup
            name='doBeneficialOwnersHaveForeignCitizenship'
            value={doBeneficialOwnersHaveForeignCitizenship}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='doBeneficialOwnersHaveForeignCitizenship'
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='doBeneficialOwnersHaveForeignCitizenship'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {errors.doBeneficialOwnersHaveForeignCitizenship && (
            <p className='radioButtonsErrorMessage'>
              {errors.doBeneficialOwnersHaveForeignCitizenship.message}
            </p>
          )}
        </Grid>
        <Grid item xs={12} className={classes.boxContainer}>
          <span>
            {t('additional-data.is-for-politically-exposed-person')}
            <Info
              title={t('additional-data.is-for-politically-exposed-person-info-title')}
              content={t('additional-data.is-for-politically-exposed-person-info-text')}
              smallIcon
              topAlign
            />
          </span>
          <RadioGroup
            name='isForPoliticallyExposedPerson'
            value={isForPoliticallyExposedPerson}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isForPoliticallyExposedPerson'
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isForPoliticallyExposedPerson'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {errors.isForPoliticallyExposedPerson && (
            <p className='radioButtonsErrorMessage'>
              {errors.isForPoliticallyExposedPerson.message}
            </p>
          )}
        </Grid>
        <Grid item xs={12} className={classes.boxContainer}>
          <span>
            {t('additional-data.is-for-person-related-with-politically-exposed-person')}
            <Info
              title={t('additional-data.related-with-politically-exposed-person-info-title')}
              content={t('additional-data.related-with-politically-exposed-person-info-text')}
              smallIcon
              topAlign
            />
          </span>
          <RadioGroup
            name='isForPoliticallyExposedRelatedPerson'
            value={isForPoliticallyExposedRelatedPerson}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isForPoliticallyExposedRelatedPerson'
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.field.required'),
                },
              })}
              name='isForPoliticallyExposedRelatedPerson'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {errors.isForPoliticallyExposedRelatedPerson && (
            <p className='radioButtonsErrorMessage'>
              {errors.isForPoliticallyExposedRelatedPerson.message}
            </p>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default AdditionalDataStep;
