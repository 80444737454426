import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    gridItem: css({
      textAlign: 'center',
    }),
    errorIconSize: css({
      width: '96px',
      height: '96px',
    }),
  }));

export default useStyles;
