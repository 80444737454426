import i18n from 'i18next';

export function isLeapYear(yyyy) {
  if (yyyy % 400 === 0) {
    return true;
  }
  if (yyyy % 100 === 0) {
    return false;
  }
  if (yyyy % 4 === 0) {
    return true;
  }

  return false;
}

export function isValidEGN(input) {
  const egn = input ? input.trim() : '';

  if (egn.length !== 10) {
    return false;
  }

  const digits = [];
  const coeffs = [2, 4, 8, 5, 10, 9, 7, 3, 6];
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  for (let i = 0; i < egn.length; i += 1) {
    const digit = parseInt(egn.charAt(i), 10);

    if (Number.isNaN(digit)) {
      break;
    }

    digits[i] = digit;
  }

  if (digits.length !== 10) {
    return false;
  }

  const dd = digits[4] * 10 + digits[5];
  let mm = digits[2] * 10 + digits[3];
  const yy = digits[0] * 10 + digits[1];
  let yyyy = null;

  if (mm >= 1 && mm <= 12) {
    yyyy = 1900 + yy;
  } else if (mm >= 21 && mm <= 32) {
    mm -= 20;
    yyyy = 1800 + yy;
  } else if (mm >= 41 && mm <= 52) {
    mm -= 40;
    yyyy = 2000 + yy;
  } else {
    return false;
  }

  days[1] += isLeapYear(yyyy) ? 1 : 0;

  if (!(dd >= 1 && dd <= days[mm - 1])) {
    return false;
  }

  let checksum = 0;

  for (let j = 0; j < coeffs.length; j += 1) {
    checksum += digits[j] * coeffs[j];
  }

  checksum %= 11;

  if (checksum === 10) {
    checksum = 0;
  }

  if (digits[9] !== checksum) {
    return false;
  }

  return true;
}

export function isValidEIK(input) {
  const t = [2, 7, 3, 5, 4, 9, 5, 7];
  let rr;
  let i;
  let chs;
  const s = input ? input.trim() : '';

  switch (s.length) {
    case 9:
      if (s === '000000000') return false;

      rr = 0;
      for (i = 0; i < 8; i += 1) {
        rr += s[i] * (i + 1);
      }
      chs = rr % 11;
      if (chs === 10) {
        rr = 0;
        for (i = 0; i < 8; i += 1) {
          rr += s[i] * (i + 3);
        }
        chs = rr % 11;
      }
      return chs % 10 === parseInt(s[8], 10);
    case 13:
      if (s === '0000000000000') return false;

      if (isValidEIK(s.substr(0, 9)) === true) {
        rr = 0;
        for (i = 8; i < 12; i += 1) {
          rr += s[i] * t[i - 8];
        }
        chs = rr % 11;
        if (chs === 10) {
          rr = 0;
          for (i = 8; i < 12; i += 1) {
            rr += s[i] * t[i - 4];
          }
          chs = rr % 11;
        }
        return chs % 10 === parseInt(s[12], 10);
      }
      return false;
    default:
      return false;
  }
}

export function requiredTextField(value) {
  return !!value.trim() || i18n.t('validation.field.required');
}

export function removeCharacterFromNumber(value) {
  return value.replace(/\D+/g, '');
}

export function mobilePhoneValidator(value, phonePrefixes) {
  const phoneRegx = /^[0-9]{9}$/.test(value);

  if (phonePrefixes.indexOf(value.slice(0, 2)) > -1 && phoneRegx) {
    return true;
  }

  return false;
}
