import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    root: css({
      borderRadius: '4px',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
      zIndex: '1',
      backgroundColor: '#fff !important',
    }),
  }));

export default useStyles;
