import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    tableBorders: css({
      border: '1.5px solid grey',
      borderCollapse: 'collapse',
    }),
    justifyText: css({
      textAlign: 'justify',
    }),
    textAlignLeft: css({
      textAlign: 'left',
    }),
    hyperlinkStyle: css({
      color: 'blue',
      textDecoration: 'underline',
    }),
  }));

export default useStyles;
