import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    root: css({
      width: '100%',
    }),
    button: css({
      width: '166px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
    largeButton: css({
      width: '213px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
    buttonOuterWrapper: css({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '32px',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        maxWidth: '400px',
        margin: '0px auto',
      },
    }),
    buttonProgress: css({
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 4,
      marginLeft: -12,
      color: '#FF6600',
    }),
    cardWrapper: css({
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '32px',
    }),
    cardOuterWrapper: css({
      display: 'inline',
      alignItems: 'center',
    }),
    cardInnerWrapper: css({
      position: 'relative',
    }),
    employeeIdentifierPush: css({
      height: '114px',
      [theme.breakpoints.down('md')]: {
        height: '118px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '115px',
      },
    }),
  }));

export default useStyles;
