import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './request-info.styles';
import InfoIcon from '../../../assets/images/info_icon.svg';
import EmailIcon from '../../../assets/images/email-confirmation.svg';
import { useApplicationState } from '../../../providers/application/application.provider';
import { signOut } from '../../../providers/application/application.actions';

const RequestInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    requestCannotContinue,
    clientNotFulfilledRequirementsForNewRequest,
    notAllowedLegalForm,
    invalidIdentificationDocument,
    confirmationEmailPending,
    resumeEmailPending,
    emailConfirmed,
    insufficientCompanyData,
    nomenclatures: { callCenterPhoneNumber },
  } = useApplicationState();

  let infoTitle = '';
  let infoSubtitle = '';

  useEffect(() => {
    if (emailConfirmed && requestCannotContinue) {
      signOut();
    }
  }, [emailConfirmed, requestCannotContinue]);

  if (requestCannotContinue) {
    infoTitle = t('request-info.title.request-cannot-continue');

    if (insufficientCompanyData) {
      infoSubtitle = (
        <div className={classes.centeredSubtitle}>
          {t('process-terminated.missing-detailed-address')}
        </div>
      );
    } else if (clientNotFulfilledRequirementsForNewRequest) {
      infoSubtitle = (
        <div className={classes.centeredSubtitle}>
          {t('process-terminated.client-not-fulfilled-requirements')}
        </div>
      );
    } else if (notAllowedLegalForm) {
      infoSubtitle = (
        <div className={classes.centeredSubtitle}>
          {t('process-terminated.not-allowed-legal-form')}
        </div>
      );
    } else if (invalidIdentificationDocument) {
      infoSubtitle = (
        <div className={classes.centeredSubtitle}>
          {t('process-terminated.invalid-identification-document')}
        </div>
      );
    } else {
      infoSubtitle = (
        <div className={classes.infoBox}>
          {t('request-info.title.request-cannot-continue.subtitle')}
          <a className={classes.callCenterBox} href={`tel: ${callCenterPhoneNumber}`}>
            {callCenterPhoneNumber}
          </a>
        </div>
      );
    }
  } else if (confirmationEmailPending) {
    infoTitle = <Trans>{t('request-info.title.confirmation-email-pending')}</Trans>;

    infoSubtitle = (
      <div>
        {t('request-info.subtitle.confirmation-email-pending.line1')}
        <br />
        {t('request-info.subtitle.confirmation-email-pending.line2')}
      </div>
    );
  } else if (resumeEmailPending) {
    infoTitle = t('request-info.title.resume-email-pending');

    infoSubtitle = <div>{t('request-info.title.resume-email-pending.line1')}</div>;
  }

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid className={classes.gridItem} item xs={12}>
        <img src={confirmationEmailPending || resumeEmailPending ? EmailIcon : InfoIcon} alt='' />
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {infoTitle}
        </Typography>
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h6' variant='h6'>
          {infoSubtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RequestInfo;
