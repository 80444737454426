import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = (stepsLength) =>
  makeStyles((theme) => ({
    closeButton: css({
      position: 'absolute',
      color: '#52AE30',
      right: '24px',
      top: '24px',
    }),
    dialog: css({
      '& .MuiDialogContent-root': {
        background: '#F6F7FA',
        width: '560px',
        height: stepsLength > 6 ? '680px' : '580px',
        textAlign: '-webkit-center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(0.2),
      },
      '& .MuiDialog-paper': {
        backgroundColor: '#F6F7FA',
        borderRadius: '32px',
        [theme.breakpoints.down('sm')]: {
          borderRadius: '0',
        },
      },
    }),
    dialogContent: css({
      padding: '0',
    }),
    stepsHeader: css({
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '34px',
      maxWidth: '460px',
      justifyContent: 'center',
      margin: '88px 24px 40px 24px',
      textAlign: 'center',
      alignItems: 'center',
      color: '#151617',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px',
      },
    }),
    stepsHeaderSecondPart: css({
      marginLeft: '10px',
      color: '#52AE30',
    }),
    stepsText: css({
      textAlign: 'left',
      fontWeight: '700',
      lineHeight: '24px',
      color: '#52AE30',
      marginLeft: '20px',
    }),
    stepsTextExpanded: css({
      textAlign: 'left',
      fontWeight: '700',
      lineHeight: '24px',
      color: '#52AE30',
    }),
    stepsDescriptionText: css({
      color: '#7D828B',
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
    }),
    roundedAccordion: css({
      '&:before': { display: 'none' },
      '&.MuiPaper-root': {
        borderRadius: '24px',
        margin: '0 0 16px 0',
      },
      '& .MuiAccordion-root': {
        backgroundColor: '#F6F7FA',
        borderRadius: '24px',
      },
    }),
    roundedAccordionSummary: css({
      borderRadius: '24px',
      height: '55px',
      '&.MuiAccordionSummary-root.Mui-expanded': {
        border: '1px solid #52AE30',
        minHeight: '55px',
      },
    }),
    roundedAccordionDetails: css({
      '&.MuiPaper-root-MuiAccordion-root.Mui-expanded': {
        borderRadius: '24px',
      },
      minHeight: '0px',
    }),
    stepsDescription: css({
      '& .MuiAccordion-root': {
        boxShadow: 'none !important',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 20px',
      maxWidth: '560px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    }),
  }));

export default useStyles;
