import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    gridItem: css({
      textAlign: 'center',
    }),
    buttonsSection: css({
      paddingTop: '16px',
    }),
    centeredSubtitle: css({
      whiteSpace: 'pre-line',
      textAlign: 'center',
    }),
    infoBox: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    callCenterBox: css({
      backgroundColor: 'rgba(50, 143, 230, 0.08)',
      color: '#328FE6',
      borderRadius: '8px',
      padding: '4px 16px ',
      marginTop: '8px',
    }),
  }));

export default useStyles;
