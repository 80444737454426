// eslint-disable-next-line import/prefer-default-export
import { useTheme } from '@mui/material';

export function filterOptions(options, { inputValue, getOptionLabel }) {
  const inputStrings = inputValue
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((s) => s);

  return inputStrings.length > 0
    ? options.filter((option) => {
        const candidate = getOptionLabel(option).toLowerCase();
        return inputStrings.every((s) => candidate.indexOf(s) > -1);
      })
    : options;
}

export const makeStyles = (fn) => {
  return fn(useTheme());
};
