import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import useStyles from './entry-step-buttons.styles';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/images/otp-money.svg';
import { ReactComponent as Receipt } from '../../../assets/images/otp-receipt.svg';
import { ReactComponent as JobSearch } from '../../../assets/images/otp-job-search.svg';

const EntryStepButtons = ({ handleButtonClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.buttonContainer}>
      <button type='button' className={classes.button} onClick={() => handleButtonClick(false)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.iconContainer}>
            <MoneyIcon />
          </div>
          <Typography className={classes.labelInfo}>
            {t('entry-step.btn-dsk-account-online')}
          </Typography>
        </div>
        <ArrowRight />
      </button>
      <button type='button' className={classes.button} onClick={() => handleButtonClick(true)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={`${classes.iconContainer} ${classes.secondaryBackground}`}>
            <Receipt />
          </div>
          <div style={{ textAlign: 'left' }}>
            <Typography className={classes.labelInfo}>
              {t('entry-step.btn-dsk-account-office')}
            </Typography>
            <Typography className={classes.officeNote}>
              {t('entry-step.btn-dsk-account-office-note')}
            </Typography>
          </div>
        </div>
        <ArrowRight className={classes.arrowRightSecondaryColor} />
      </button>
      <button
        type='button'
        className={classes.button}
        onClick={() => handleButtonClick(undefined, true)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.iconContainer}>
            <JobSearch />
          </div>
          <Typography className={classes.labelInfo}>
            {t('entry-step.btn-dsk-capital-account')}
          </Typography>
        </div>
        <ArrowRight />
      </button>
    </Grid>
  );
};

export default EntryStepButtons;
