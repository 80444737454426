import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    loaderContainer: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    documentRow: css({
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 4px 8px rgba(73, 92, 136, 0.15)',
      borderRadius: '19px',
      padding: '12px 24px',
      marginBottom: '16px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        padding: '12px 20px',
      },
    }),
    documentTextLabel: css({
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '600',
    }),
    documentIcon: css({
      width: ' 45px',
      height: '45px',
      '@media (hover: hover)': {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }),
    documentsText: css({
      textAlign: 'center',
      fontSize: '16px',
      color: '#1B1F27',
      lineHeight: '24px',
      marginTop: '26px',
      marginBottom: '26px',
    }),
    countdownText: css({
      textAlign: 'center',
      fontSize: '16px',
      color: '#52AE32',
      lineHeight: '24px',
      marginTop: '8px',
    }),
    documentIconAlign: css({
      textAlign: 'right',
    }),
    itemCustomized: css({
      paddingTop: '0px !important',
    }),
    infoCard: css({
      borderRadius: '16px',
      background: '#EEF7EA',
      border: 'none',
      padding: '15px 10px 15px 17px',
      display: 'flex',
      lineHeight: '24px',
      boxShadow: 'none',
    }),
    infoCardText: css({
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
    }),
    cardLink: css({
      color: '#31681D !important',
      '@media (hover: hover)': {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
  }));

export default useStyles;
