import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useTheme, useMediaQuery } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import useStyles from './consent-dialog.styles';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const ConsentDialog = ({ open, title, handleClose, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle className={classes.dialogTitle}>
        {title}
        <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default ConsentDialog;
