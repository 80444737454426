import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#52ae30',
      light: '#74bf1e',
    },
    secondary: {
      main: '#52ae30',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1240,
      lg: 1336,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: `"Source Sans 3", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji"`,
    h1: {
      fontFamily: `"Squad",ui-sans-serif,system-ui,-system-ui,-apple-system,BlinkMacSystemFont,
        "Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '32px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        autoComplete: 'off',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          '& label': {
            color: '#A6ADBA',
          },
          '& fieldset': {
            borderColor: '#A6ADBA ',
          },
          '& input': {
            color: '#151617',
            fontWeight: 700,
            '&::placeholder': {
              color: '#BDBDBD',
              fontWeight: 400,
            },
          },
          '& .Mui-error': {
            color: '#ff435a !important',
          },
          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff435a !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '16px',
          borderRadius: 30,
          textTransform: 'none',
          padding: '16px 32px',
          height: '48px',
          backgroundColor: '#FF6600',
        },
        containedPrimary: {
          color: 'white',
          boxShadow: 'none',
          backgroundColor: '#FF6600',
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: '#FF8533',
              boxShadow: 'none',
            },
          },
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: '#FF6600',
              boxShadow: 'none',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#DDD',
          },
        },
        textPrimary: {
          backgroundColor: 'transparent !important',
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        containedSecondary: {
          backgroundColor: '#52ae30 !important',
          color: 'white',
          boxShadow: 'none',
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: '#74bf1e !important',
              boxShadow: 'none',
            },
          },
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: '#52ae30 !important',
              boxShadow: 'none',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#DDD !important',
          },
        },
        textSecondary: {
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        label: {
          fontSize: 16,
        },
      },
      defaultProps: {
        disableRipple: true,
        color: 'primary',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          minHeight: '36px',
          padding: '0px 4px 0px 6px !important',
          color: '#424344',
          fontWeight: 700,
          '&::placeholder': {
            color: '#BDBDBD',
            fontWeight: 400,
          },
        },
        clearIndicator: {
          display: 'none',
        },
        popupIndicator: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          paddingLeft: '18px !important',
          paddingRight: '18px !important',
          [`& legend`]: {
            marginLeft: '18px',
          },
        },
      },
    },
    PrivateNotchedOutline: {
      styleOverrides: {
        root: {
          paddingLeft: 25,
          paddingRight: 25,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          left: '18px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 4px 40px #95959529',
        },
        elevation2: {
          boxShadow: '0 1rem 3rem rgba(73,92,136,0.15)',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: '#ffffff',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#151617',
          fontWeight: 700,
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        colorSecondary: {
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        root: {
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '16px',
          lineHeight: '1em',
          fontWeight: 700,
          '&.Mui-active': {
            fontWeight: 700,
          },
          '&.Mui-completed': {
            fontWeight: 700,
          },
        },
        iconContainer: {
          paddingRight: '10px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 12px 16px 12px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          marginRight: '4px',
          paddingLeft: '14px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: '6px !important',
          paddingBottom: '0px !important',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media (min-width: 768px)': {
            borderRadius: 32,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: '#ffffff',
          color: '#000000',
        },
        deleteIcon: {
          color: '#52ae30',
          '@media (hover: hover)': {
            '&:hover': {
              color: '#74bf1e',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(27, 130, 227, 1)',
          borderRadius: '16px',
          fontSize: '16px',
          padding: '16px 24px',
          whiteSpace: 'pre-line',
          '& p': {
            margin: '0',
          },
        },
        arrow: {
          color: 'rgba(27, 130, 227, 1)',
          width: '0.77rem !important',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 8px rgba(73, 92, 136, 0.15) !important',
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '& .MuiRating-iconFilled': {
            color: '#52AE30',
          },
          '& .MuiRating-iconHover': {
            color: '#52AE30',
          },
          '& .MuiRating-icon': {
            color: '#52AE30',
            marginRight: '15.5px',
          },
        },
      },
    },
  },
});

export default theme;
