/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import i18n from 'i18next';
import Info from '../../info/info.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  resetInvalidEmployeeId,
  saveClientDataAsync,
  setAcceptedPersonalDataConsent,
} from '../../../providers/application/application.actions';
import ConsentDialog from '../../consent-dialog/consent-dialog.component';
import PhoneNumber from '../phone-number/phone-number.component';
import {
  isValidEGN,
  isValidEIK,
  requiredTextField,
  removeCharacterFromNumber,
  mobilePhoneValidator,
} from '../../../utils/validation.utils';
import { ReactComponent as CheckboxIconChecked } from '../../../assets/images/checbox-checked.svg';
import { ReactComponent as CheckboxIconUnchecked } from '../../../assets/images/checbox-unchecked.svg';
import InvestorInformationBulletin from '../../consents/investor-information-bulletin/investor-information-bulletin.component';
import useStyles from './client-data-step.styles';

const ClientDataStep = ({ formRef, referrerUrl }) => {
  const { register, errors, handleSubmit, getValues } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    requestNumber,
    clientIdentifier,
    representativeIdentifier,
    emailAddress,
    emailConfirmed,
    mobilePhoneNumber,
    mobilePhoneConfirmed,
    acceptedPersonalDataConsent,
    isFromBranch,
    employeeIdentifier,
    invalidEmployeeID,
    utmMedium,
    nomenclatures: { phoneNumberAllowedPrefixes, gdprConsentUrl },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');

  const dialogOpen = (text, title) => {
    setDialogContent(text);
    setDialogTitle(title);
    setShowDialog(true);
  };

  const dialogClose = () => {
    setShowDialog(false);
  };

  const handlePersonalDataConsentChange = () => {
    dispatch(setAcceptedPersonalDataConsent(!acceptedPersonalDataConsent));
  };

  const handleInvestorInformationBulletinPreview = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dialogOpen(<InvestorInformationBulletin />, t('investor-information-bulletin.title'));
  };

  const handleChange = () => {
    if (invalidEmployeeID) {
      dispatch(resetInvalidEmployeeId());
    }
  };

  const onSubmit = () => {
    const data = getValues();
    const phoneNumber = data.mobilePhoneNumber.replace(/^0+/, '');
    saveClientDataAsync(
      {
        ...data,
        mobilePhoneNumber: phoneNumber,
        requestNumber,
        isFromBranch,
        referrerUrl,
        utmMedium,
      },
      dispatch
    );
  };

  useEffect(() => {
    // HACK: React Hook Form is unable to automatically focus errors in Firefox.
    if ((errors && Object.keys(errors).length > 0) || invalidEmployeeID) {
      const element = document.getElementsByClassName('Mui-error')[0];
      if (element) {
        const elementRect = element.getBoundingClientRect();
        window.scrollTo(0, elementRect.top + window.scrollY - window.innerHeight / 2);
      }
    }
  }, [errors, invalidEmployeeID]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container justifyContent='center' className={classes.formContainer}>
        {isFromBranch ? (
          <Grid item xs={12} className={classes.employeeIdentifierWrapper}>
            <TextField
              label={t('client-data-step.employee-identifier')}
              name='employeeIdentifier'
              defaultValue={employeeIdentifier}
              disabled={emailConfirmed}
              autoFocus={isFromBranch}
              inputProps={{ maxLength: 8 }}
              error={!!errors.employeeIdentifier || invalidEmployeeID}
              helperText={
                (errors.employeeIdentifier && errors.employeeIdentifier.message) ||
                (invalidEmployeeID && t('validation.employee-identifier-invalid'))
              }
              onChange={handleChange}
              inputRef={register({
                validate: (value) =>
                  !!value.trim() || i18n.t('validation.employee-identifier-invalid'),
                pattern: {
                  value: /^[A-Za-z][0-9]{7}|[A-Za-z]{2}[0-9]{6}$/,
                  message: t('validation.employee-identifier-invalid'),
                },
              })}
              placeholder={t('client-data-step.employee-identifier-placeholder')}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <TextField
            label={t('client-data-step.client-identifier')}
            name='clientIdentifier'
            defaultValue={clientIdentifier}
            disabled={emailConfirmed}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Info
                    title={t('client-data-step.client-identifier')}
                    content={t('client-data-step.client-identifier-info-text')}
                  />
                </InputAdornment>
              ),
            }}
            autoFocus={!isFromBranch}
            inputProps={{
              maxLength: 13,
            }}
            error={!!errors.clientIdentifier}
            helperText={errors.clientIdentifier && errors.clientIdentifier.message}
            inputRef={register({
              validate: {
                requiredTextField,
                validIdentifier: (value) => isValidEIK(value) || t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} className={classes.childrenContainer}>
          <TextField
            label={t('client-data-step.representative-identifier')}
            name='representativeIdentifier'
            defaultValue={representativeIdentifier}
            disabled={emailConfirmed}
            inputProps={{
              maxLength: 10,
            }}
            error={!!errors.representativeIdentifier}
            helperText={errors.representativeIdentifier && errors.representativeIdentifier.message}
            inputRef={register({
              validate: {
                requiredTextField,
                validEGN: (value) => isValidEGN(value) || t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} className={classes.childrenContainer}>
          <TextField
            label={t('client-data-step.email-address')}
            name='emailAddress'
            type='email'
            defaultValue={emailAddress}
            disabled={emailConfirmed}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Info
                    title={t('client-data-step.email-address')}
                    content={t('client-data-step.email-info-text')}
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 40,
            }}
            error={!!errors.emailAddress}
            helperText={errors.emailAddress && errors.emailAddress.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/,
                message: t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} className={classes.childrenContainer}>
          <PhoneNumber
            defaultValue={mobilePhoneNumber}
            propertyName='mobilePhoneNumber'
            inputRef={register({
              validate: {
                requiredTextField,
                validPhoneNumber: (value) => {
                  return (
                    mobilePhoneValidator(
                      removeCharacterFromNumber(value).replace(/^0+/, ''),
                      phoneNumberAllowedPrefixes
                    ) || t('validation.text-field.invalid')
                  );
                },
              },
            })}
            errors={errors}
            disabled={mobilePhoneConfirmed}
          />
        </Grid>

        <Grid item xs={12} className={classes.childrenContainer}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                name='acceptedPersonalDataConsent'
                checked={acceptedPersonalDataConsent}
                onClick={handlePersonalDataConsentChange}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.checkbox-field.required'),
                  },
                })}
                icon={<CheckboxIconUnchecked />}
                checkedIcon={<CheckboxIconChecked />}
                style={{ padding: '0px 8px 0px 0px' }}
              />
            }
            label={
              <span>
                {t('client-data-step.accepted-personal-data-consent')}{' '}
                <a href={gdprConsentUrl} target='_blank' rel='noopener noreferrer'>
                  {t('client-data-step.accepted-personal-data-consent-link')}
                </a>{' '}
                {t('client-data-step.and')}{' '}
                <a onClick={handleInvestorInformationBulletinPreview} href='#'>
                  {t('client-data-step.accepted-investor-information-bulletin-consent-link')}
                </a>
              </span>
            }
          />
          {errors.acceptedPersonalDataConsent && !acceptedPersonalDataConsent && (
            <p className='checkboxErrorMessage'>{errors.acceptedPersonalDataConsent.message}</p>
          )}
        </Grid>
      </Grid>
      <ConsentDialog open={showDialog} title={dialogTitle} handleClose={dialogClose}>
        {dialogContent}
      </ConsentDialog>
    </form>
  );
};

export default ClientDataStep;
