import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    boxContainer: css({
      padding: '16px',
      borderRadius: '24px',
      boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
    }),
    inputContainer: css({
      '& > :nth-child(2)': {
        marginTop: '16px',
      },
    }),
    subsection: css({
      marginTop: '16px',
    }),
    subsectionLabel: css({
      marginBottom: '16px',
    }),
  }));

export default useStyles;
