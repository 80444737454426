import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    footer: css({
      backgroundColor: '#52ae30',
      borderRadius: '40px 40px 0 0',
      margin: 'auto 0 0 0',
    }),
    footerContainer: css({
      lineHeight: '1.125',
      padding: '30px 96px 14px 96px',
      verticalAlign: 'top',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        padding: '24px',
      },
    }),
    footerDskLogo: css({
      maxWidth: '130px',
    }),
    footerLogoText: css({
      position: 'relative',
      top: '-9px',
      color: '#ffffff',
      marginRight: '16px',
      marginLeft: '32px',
      paddingLeft: '32px',
      borderInlineStart: '1px solid',
      [theme.breakpoints.down('md')]: {
        marginLeft: '16px',
        marginTop: '16px',
        paddingLeft: '0px',
        borderInlineStart: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }),
    footerLogoOtp: css({
      maxWidth: '110px',
      position: 'relative',
      top: '-2px',
      [theme.breakpoints.down('md')]: {
        marginTop: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }),
    footerText: css({
      color: '#ffffff',
    }),
    footerCopyright: css({
      color: '#ffffff',
      paddingTop: '4px',
      textAlign: 'right',
      [theme.breakpoints.between('xs', 'md')]: {
        display: 'flex',
        flexDirection: 'column-reverse',
        textAlign: 'left',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    }),
    footerCallCenter: css({
      display: 'none',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        marginTop: '24px',
      },
    }),
    footerCallCenterText: css({
      color: '#ffffff',
    }),
    bankAddress: css({
      marginTop: '10px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px',
      },
    }),
  }));

export default useStyles;
