import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = (numberOfDigits) =>
  makeStyles(() => ({
    buttonOuterWrapper: css({
      display: 'flex',
      alignItems: 'center',
    }),
    buttonInnerWrapper: css({
      position: 'relative',
    }),
    buttonProgress: css({
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    }),
    generateCodeBtnContainer: css({
      display: 'flex',
      justifyContent: 'center',
      '@media (max-width: 320px)': {
        marginTop: '8px',
      },
    }),
    generateCodeButton: css({
      paddingRight: '0px',
      paddingLeft: '0px',
      height: '16px',
      marginTop: '8px',
      textDecoration: 'underline',
      '@media (hover: none)': {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
    digitContainer: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    digitInputsWrapper: css({
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      columnGap: '6.4px',
    }),
    smallGap: css({
      columnGap: '4px',
    }),
    digitInput: css({
      width: '48px',
      height: '48px',
      border: '1px solid #D8D8D8',
      boxSizing: 'border-box',
      boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '16px',
      textAlign: 'center',
      fontSize: '18px',
      '&:focus-visible': {
        outlineColor: '#52ae30 !important',
      },
    }),
    smallDigitInput: css({
      width: '36px',
      height: '36px',
      border: '1px solid #D8D8D8',
      boxSizing: 'border-box',
      boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      textAlign: 'center',
      fontSize: '16px',
      '&:focus-visible': {
        outlineColor: '#52ae30 !important',
      },
      '@media (max-width: 320px)': {
        width: numberOfDigits > 6 ? '30px' : '36px',
        height: numberOfDigits > 6 ? '30px' : '36px',
      },
    }),
  }));

export default useStyles;
