import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    closeButton: css({
      position: 'absolute',
      right: '16px',
      top: '16px',
      background: 'transparent !important',
    }),
    dialogTitle: css({
      minHeight: '50px',
      paddingRight: '75px',
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingBottom: '0',
      '@media (max-width: 320px)': {
        paddingRight: '62px',
      },
    }),
    dialogContent: css({
      marginTop: '0',
      '@media (max-width: 320px)': {
        paddingLeft: '18px',
        paddingRight: '18px',
      },
    }),
    closeIcon: css({
      color: theme.palette.primary.main,
    }),
  }));

export default useStyles;
