/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, createRef } from 'react';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import VerificationCode from '../validation-code/validation-code.component';
import Loader from '../loader/loader.component';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import {
  saveDocumentsDataAsync,
  loadDocumentsDataAsync,
  setDocumentsSigningCode,
  generateDocumentsSigningCodeAsync,
  validateDocumentsSigningCodeAsync,
} from '../../../providers/application/application.actions';
import useStyles from './documents-data-step.styles';
import { ReactComponent as DocumentIcon } from '../../../assets/images/document-icon.svg';

const DocumentsDataStep = ({ formRef }) => {
  const { errors, reset, setError, clearErrors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    documents,
    nomenclatures: { eChannelServicesForBusinessClientstUrl },
    loadingDocuments,
    showDocumentsVerificationData,
    documentsSigningCode,
    wrongSmsVerificationCode,
    generateDocumentsSigningCodeLoading,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const ref = createRef();

  const [countdown, setCountdown] = React.useState(60);

  const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (showDocumentsVerificationData) {
      if (ref.current.validateVerificationCode(documentsSigningCode)) {
        validateDocumentsSigningCodeAsync({ documentsSigningCode }, dispatch);
      }
    } else {
      saveDocumentsDataAsync(dispatch);
    }
  };

  const startCountdown = () => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
        }
        return prev > 0 ? prev - 1 : 0;
      });
    }, 1000);
  };

  const generateNewCode = () => {
    reset({ documentsSigningCode: '' });
    generateDocumentsSigningCodeAsync(dispatch, () => {
      setCountdown(60);
      startCountdown();
    });
  };

  const openDocument = (id) => {
    window.open(
      `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/DownloadDocument?documentTemplateId=${id}`,
      '_blank'
    );
  };

  useEffect(() => {
    const timeOut = 5000;
    if (documents.length === 0) {
      setTimeout(() => loadDocumentsDataAsync(dispatch, timeOut), timeOut);
    }
  }, []);

  useEffect(() => {
    if (showDocumentsVerificationData) {
      startCountdown();
    }
  }, [showDocumentsVerificationData]);

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      {loadingDocuments ? (
        <Grid container className={classes.loaderContainer}>
          <Loader title={t('general.loader-title')} message={t('documents-data.loader-message')} />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.itemCustomized}>
            {documents.map((documentTemplate) => (
              <React.Fragment key={documentTemplate.id}>
                <Grid
                  container
                  className={classes.documentRow}
                  onClick={() => openDocument(documentTemplate.id)}
                >
                  <Grid item xs={10} className={classes.documentTextLabel}>
                    <Trans>{documentTemplate.name}</Trans>
                  </Grid>
                  <Grid item xs={2} className={classes.documentIconAlign}>
                    <DocumentIcon className={classes.documentIcon} />
                  </Grid>
                </Grid>
                {errors.documents && errors.documents[`${documentTemplate.id}`] && (
                  <p className='checkboxErrorMessage'>
                    {errors.documents[`${documentTemplate.id}`].message}
                  </p>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '8px' }}>
            <Card className={classes.infoCard}>
              <Grid container spacing={0} className={classes.infoCardText}>
                {t('documents-data.card-info-text')}{' '}
                <a
                  href={eChannelServicesForBusinessClientstUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.cardLink}
                >
                  {t('documents-data.card-info-link')}
                </a>
              </Grid>
            </Card>
          </Grid>
          {showDocumentsVerificationData && (
            <Grid container style={{ marginLeft: '0px' }}>
              <Grid item xs={12}>
                <Box className={classes.documentsText}>
                  <Trans>{t('document-verification.code-title')}</Trans>
                </Box>
                <VerificationCode
                  propertyName='documentsSigningCode'
                  numberOfDigits={8}
                  errors={errors}
                  disabled={false}
                  verificationCode={documentsSigningCode}
                  setError={setError}
                  clearErrors={clearErrors}
                  setVerificationCode={setDocumentsSigningCode}
                  hasError={!!wrongSmsVerificationCode}
                  errorMsg={
                    wrongSmsVerificationCode && (
                      <Trans>{t('validation.wrong-sms-verification-code')}</Trans>
                    )
                  }
                  ref={ref}
                  disabledGenerateCode={generateDocumentsSigningCodeLoading}
                  showLinkToRegenerateCode={countdown === 0}
                  regenarateAction={generateNewCode}
                  generateVerificationCodeLoading={generateDocumentsSigningCodeLoading}
                  isSuccessfullyRegenerated={false}
                  buttonTitle={t('general.generate-code-button')}
                />
                {countdown > 0 && (
                  <Box className={classes.countdownText}>
                    <Trans>{t('document-verification.countdown-text', { countdown })}</Trans>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

export default DocumentsDataStep;
