import React, { useState, useEffect } from 'react';

const SideImage = ({ src }) => {
  const [state, setState] = useState({ loaded: false, src: null });

  useEffect(() => {
    const image = new Image();
    image.onload = () => setState({ loaded: true, src });
    image.src = src;

    return () => {
      if (image) {
        image.onload = null;
      }
    };
  }, [src]);

  const loadedNewImage = state.loaded && state.src === src;
  return (
    <div
      style={{
        paddingTop: `${loadedNewImage ? 0 : (841 / 1255) * 100}%`,
        backgroundColor: '#FFFFFF',
        opacity: loadedNewImage ? 1 : 0,
        transition: `opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1)`,
      }}
    >
      {loadedNewImage ? (
        <img
          src={state.src}
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '24px',
          }}
          alt='side'
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SideImage;
