const ApplicationActionTypes = {
  RESET_APPLICATION_STATE: 'RESET_APPLICATION_STATE',
  GO_TO_PREVIOUS_STEP: 'GO_TO_PREVIOUS_STEP',
  SET_ACCEPTED_PERSONAL_DATA_CONSENT: 'SET_ACCEPTED_PERSONAL_DATA_CONSENT',
  SAVE_CLIENT_DATA_START: 'SAVE_CLIENT_DATA_START',
  SAVE_CLIENT_DATA_SUCCESS: 'SAVE_CLIENT_DATA_SUCCESS',
  RELOAD_REQUIRED_FAILURE: 'RELOAD_REQUIRED_FAILURE',
  GENERAL_SERVER_FAILURE: 'GENERAL_SERVER_FAILURE',
  GENERATE_VERIFICATION_CODE_START: 'GENERATE_VERIFICATION_CODE_START',
  GENERATE_VERIFICATION_CODE_SUCCESS: 'GENERATE_VERIFICATION_CODE_SUCCESS',
  CHANGE_GENERIC_CONSENT: 'CHANGE_GENERIC_CONSENT',
  SAVE_GENERAL_TERMS_DATA_START: 'SAVE_GENERAL_TERMS_DATA_START',
  SAVE_GENERAL_TERMS_DATA_SUCCESS: 'SAVE_GENERAL_TERMS_DATA_SUCCESS',
  SAVE_ADDITIONAL_DATA_START: 'SAVE_ADDITIONAL_DATA_START',
  SAVE_ADDITIONAL_DATA_SUCCESS: 'SAVE_ADDITIONAL_DATA_SUCCESS',
  SAVE_DOCUMENTS_DATA_START: 'SAVE_DOCUMENTS_DATA_START',
  SAVE_DOCUMENTS_DATA_SUCCESS: 'SAVE_DOCUMENTS_DATA_SUCCESS',
  LOAD_DOCUMENTS_DATA_START: 'LOAD_DOCUMENTS_DATA_START',
  LOAD_DOCUMENTS_DATA_SUCCESS: 'LOAD_DOCUMENTS_DATA_SUCCESS',
  SAVE_IDENTIFICATION_DATA_START: 'SAVE_IDENTIFICATION_DATA_START',
  SAVE_IDENTIFICATION_DATA_SUCCESS: 'SAVE_IDENTIFICATION_DATA_SUCCESS',
  START_SESSION_START: 'LOAD_NOMENCLATURES_START',
  START_SESSION_SUCCESS: 'LOAD_NOMENCLATURES_SUCCESS',
  SET_ERROR_OCCURED: 'SET_ERROR_OCCURED',
  SET_SELECTED_BUSINESS_RELATION_PURPOSE: 'SET_SELECTED_BUSINESS_RELATION_PURPOSE',
  SET_SELECTED_FUNDS_ORIGIN: 'SET_SELECTED_FUNDS_ORIGIN',
  SET_ACCEPTED_BORICA_CONSENT: 'SET_ACCEPTED_BORICA_CONSENT',
  SET_ACCEPTED_ONLINE_IDENTIFICATION_CONSENT: 'SET_ACCEPTED_ONLINE_IDENTIFICATION_CONSENT',
  SET_SMS_CODE_VERIFICATION: 'SET_SMS_CODE_VERIFICATION',
  SET_ACCEPTED_CONSENT: 'SET_ACCEPTED_CONSENT',
  SAVE_RATING_DATA_START: 'SAVE_RATING_DATA_START',
  SAVE_RATING_DATA_SUCCESS: 'SAVE_RATING_DATA_SUCCESS',
  GENERATE_DOCUMENT_SIGNING_CODE_START: 'GENERATE_DOCUMENT_SIGNING_CODE_START',
  GENERATE_DOCUMENT_SIGNING_CODE_SUCCESS: 'GENERATE_DOCUMENT_SIGNING_CODE_SUCCESS',
  SET_DOCUMENTS_SIGNING_CODE: 'SET_DOCUMENTS_SIGNING_CODE',
  COMPLETE_IDENTIFICATION_START: 'COMPLETE_IDENTIFICATION_START',
  COMPLETE_IDENTIFICATION_SUCCESS: 'COMPLETE_IDENTIFICATION_SUCCESS',
  VALIDATE_DOCUMENTS_SIGNING_CODE_START: 'VALIDATE_DOCUMENTS_SIGNING_CODE_START',
  VALIDATE_DOCUMENTS_SIGNING_CODE_SUCCESS: 'VALIDATE_DOCUMENTS_SIGNING_CODE_SUCCESS',
  SET_UTM_MEDIUM: 'SET_UTM_MEDIUM',
  SET_IS_FROM_BRANCH: 'SET_IS_FROM_BRANCH',
  SET_SELECTED_PACKAGE_ID: 'SET_SELECTED_PACKAGE_ID',
  RESET_INVALID_EMPLOYEE_ID: 'RESET_INVALID_EMPLOYEE_ID',
  GET_STEPS_BY_PROCESS_START: 'GET_STEPS_BY_PROCESS_START',
  GET_STEPS_BY_PROCESS_SUCCESS: 'GET_STEPS_BY_PROCESS_SUCCESS',
  SAVE_PACKAGE_DATA_START: 'SAVE_PACKAGE_DATA_START',
  SAVE_PACKAGE_DATA_SUCCESS: 'SAVE_PACKAGE_DATA_SUCCESS',
  SET_RATE_SCORE: 'SET_RATE_SCORE',
  SET_DELIVERY_BRANCH_ID: 'SET_DELIVERY_BRANCH_ID',
  SET_SELECTED_DEBIT_CARD_ADDRESS: 'SET_SELECTED_DEBIT_CARD_ADDRESS',
  SET_CURRENT_FIELD: 'SET_CURRENT_FIELD',
  SAVE_DEBIT_CARD_DATA_START: 'SAVE_DEBIT_CARD_DATA_START',
  SAVE_SAVE_DEBIT_CARD_DATA_SUCCESS: 'SAVE_SAVE_DEBIT_CARD_DATA_SUCCESS',
  CHECK_GENERAL_ERROR_VALUE: 'CHECK_GENERAL_ERROR_VALUE',
};

export default ApplicationActionTypes;
