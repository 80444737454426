import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import Layout from './components/layout/layout.component';
import Request from './pages/request/request.component';
import ErrorDialog from './components/error-dialog/error-dialog.component';
import ErrorBoundary from './components/error-boundary/error-boundary.component';
import { ApplicationStateProvider } from './providers/application/application.provider';
import StartSession from './pages/start-session/start-session.component';
import CompleteIdentification from './pages/completed-identification/completed-identification.component';
import './App.css';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApplicationStateProvider>
          <ErrorDialog />
          <Layout>
            <ErrorBoundary>
              <Switch>
                <Route exact path='/'>
                  <StartSession />
                </Route>
                <Route exact path='/Request'>
                  <Request />
                </Route>
                <Route exact path='/IdentificationCompleted'>
                  <CompleteIdentification />
                </Route>
              </Switch>
            </ErrorBoundary>
          </Layout>
        </ApplicationStateProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
