import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    stepsWrapper: css({
      display: 'flex',
      justifyContent: 'center',
      height: '92px',
      paddingBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    }),
    stepsContainer: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: '-webkit-center',
      padding: '16px 25px',
      justifyContent: 'space-between',
      width: '450px',
      boxShadow: '0px 4px 8px 0px rgba(73, 92, 136, 0.15)',
      [theme.breakpoints.down('md')]: {
        width: '320px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '20px 16px',
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '0 0 32px 32px',
    }),
    stepperButton: css({
      float: 'left',
      boxShadow: '0px 4px 8px rgba(73, 92, 136, 0.15) !important',
      width: '48px',
      height: '48px',
      '@media (max-width: 320px)': {
        width: '32px',
        height: '32px',
      },
      '@media (hover: hover)': {
        '&:hover': {
          cursor: 'pointer',
          color: theme.palette.primary.light,
        },
      },
    }),
    stepperButtonContainer: css({
      width: '48px',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'baseline',
      },
      '@media (max-width: 320px)': {
        width: '32px',
        height: '32px',
      },
    }),
    backButtonDisabled: css({
      float: 'left',
      color: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 4px 8px rgba(73, 92, 136, 0.15) !important',
      width: '48px',
      height: '48px',
      '@media (max-width: 320px)': {
        width: '32px',
        height: '32px',
      },
    }),
    arrowBackButton: css({
      color: '#52AE30',
      '@media (max-width: 320px)': {
        width: '16px',
        height: '16px',
      },
    }),
    infoIcon: css({
      color: '#1B82E3',
      '@media (max-width: 320px)': {
        width: '16px',
        height: '16px',
      },
    }),
    logoContainer: css({
      backgroundColor: '#FFFFFF',
      borderRadius: '0 0 32px 32px',
      height: '72px',
      marginBottom: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }),
    dskLogoMobile: css({
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '24px',
      },
    }),
    stepperContainer: css({
      [theme.breakpoints.down('sm')]: {
        marginTop: '12px',
      },
      '@media (max-width: 320px)': {
        marginTop: '12px',
      },
    }),
    progressBar: css({
      position: 'relative',
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '1.5',
    }),
    progressBarDimensions: css({
      width: '300px',
      height: '25px',
      borderRadius: '32px',
      [theme.breakpoints.down('md')]: {
        width: '180px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '270px',
      },
      '@media (max-width: 380px)': {
        width: '200px',
      },
      '@media (max-width: 280px)': {
        width: '170px',
      },
    }),
    progressBarBackground: css({
      backgroundColor: 'rgba(82, 174, 48, 0.1)',
      color: 'rgba(64, 139, 38, 0.37)',
      textAlign: 'center',
    }),
    progressBarForegroundContainer: css({
      position: 'absolute',
      top: '0',
      left: '0',
      width: '0',
      overflow: 'hidden',
      borderRadius: '32px',
    }),
    progressBarForeground: css({
      backgroundColor: 'rgb(82, 174, 48)',
      color: 'rgba(255, 255, 255, 1)',
      textAlign: 'center',
    }),
  }));

export default useStyles;
