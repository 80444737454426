import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    formContainer: css({
      width: '100%',
      maxWidth: '400px',
      margin: '0px auto',
    }),
    radioRaw: css({
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      padding: '16px',
      borderRadius: '32px',
      marginBottom: '12px',
      boxShadow: '0px 4px 8px 0px rgba(73, 92, 136, 0.15)',
    }),
    activeRow: css({
      border: '1px solid #52AE30',
      boxShadow: 'none',
    }),
    radioButtonLabel: css({
      marginRight: 0,
      position: 'absolute',
      width: '100%',
    }),
    divider: css({
      borderWidth: '1px',
      borderColor: '#D0D8E8',
      marginLeft: '34px',
    }),
    radioLabel: css({
      marginLeft: '16px',
      fontSize: '16px',
      fontWeight: 700,
      color: '#151617',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    }),
    iconDivider: css({
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
    }),
    bankOfficeSelectionHeader: css({
      width: '100%',
      maxWidth: '300px',
      color: '#151617',
      textAlign: 'center',
      margin: '24px auto',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    }),
    bankOfficeSelection: css({
      '& input': {
        fontWeight: 400,
      },
      '& button': {
        marginRight: '8px',
      },
    }),
    companyNameInput: css({
      marginTop: '32px',
      '& input': {
        textTransform: 'uppercase',
      },
    }),
    cardHolderBox: css({
      width: '100%',
      marginTop: '24px',
      padding: '16px 24px',
      borderRadius: '16px',
      backgroundColor: '#F6F7FA',
    }),
    cardHolderLabel: css({
      color: '#000',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    }),
    cardHolderName: css({
      color: '#7D828B',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '32px',
    }),
    debitCardForm: css({
      marginTop: '32px',
    }),
    inputRow: css({
      marginBottom: '16px',
      '& button': {
        marginRight: '8px',
      },
    }),
  }));

export default useStyles;
