import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    contentContainer: css({
      [theme.breakpoints.between('md', 'xl')]: {
        marginRight: '-48px',
        marginLeft: '-48px',
        width: 'fit-content',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
      },
      '@media (max-width: 320px)': {
        width: 'auto',
        padding: 0,
      },
    }),
    otpLogoContainer: css({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '32px',
    }),
    header: css({
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '40px',
      lineHeight: '48px',
      textAlign: 'center',
      alignItems: 'center',
      color: '#151617',
      [theme.breakpoints.down('md')]: {
        paddingTop: '32px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    }),
    subheader: css({
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
      alignItems: 'center',
      textAlign: 'center',
      color: '#7D828B',
      marginTop: '40px',
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('md')]: {
        marginTop: '32px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    }),
    greenText: css({
      color: '#52ae30',
    }),
    dskLogoMobile: css({
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 !important',
      },
    }),
  }));

export default useStyles;
