import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useStyles from './footer.styles';
import DskLogo from '../../assets/images/dsk_logo.svg';
import OtpLogo from '../../assets/images/otp_logo.svg';
import PhoneIcon from '../../assets/images/phone_icon_white.svg';
import { useApplicationState } from '../../providers/application/application.provider';

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    nomenclatures: { callCenterPhoneNumber },
  } = useApplicationState();

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.footerContainer}>
        <Grid item xs={12} md={6}>
          <img src={DskLogo} alt='' className={classes.footerDskLogo} />
          <Box component='span' className={classes.footerLogoText}>
            {t('footer.part-of')}
          </Box>
          <img src={OtpLogo} alt='' className={classes.footerLogoOtp} />
        </Grid>
        <Grid item xs={12} md={1}>
          <Box
            component='a'
            href={`tel: ${callCenterPhoneNumber}`}
            className={classes.footerCallCenter}
          >
            <img src={PhoneIcon} alt='' />
            <Box component='span' className={classes.footerCallCenterText}>
              {`${t('footer.call-center')} ${callCenterPhoneNumber}`}
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.footerCopyright} xs={12} md={5}>
          {t('footer.copyright', { year: new Date().getFullYear() })}
          <div className={classes.bankAddress}>{t('footer.bank-address')}</div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
