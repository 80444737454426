import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    generalTermsContainer: css({
      maxWidth: '410px',
      margin: '0px auto',
    }),
    generatedCode: css({
      textAlign: 'center',
      marginTop: '16px',
      color: theme.palette.primary.main,
    }),
  }));

export default useStyles;
