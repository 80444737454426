import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useTheme, useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import useStyles from './error-dialog.styles';
import Error from '../error/error.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import { setErrorOccured } from '../../providers/application/application.actions';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const ErrorDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { errorOccured } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const handleClose = () => {
    dispatch(setErrorOccured(false));
  };

  return (
    <Dialog open={!!errorOccured} onClose={handleClose} fullScreen={fullScreen}>
      <DialogContent className={classes.dialogContent}>
        <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
        <Error />
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
