import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    identificationContainer: css({
      display: 'flex',
      lineHeight: '24px',
    }),
    topMargin: css({
      marginTop: '16px',
    }),
    marginCheckbox: css({
      marginBottom: '-8px',
    }),
    boricaCard: css({
      alignItems: 'center',
      borderRadius: '24px',
      background: '#EEF7EA',
      color: '#52AE30',
      padding: '19px 0px',
      boxShadow: 'none',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '320px',
        marginTop: '8px',
        marginLeft: '66.5px',
      },
      [theme.breakpoints.up('xs')]: {
        width: 'auto',
        marginTop: '16px',
        marginLeft: '0px',
      },
    }),
    border: css({
      borderBottom: ' 1px solid #E3E8F1',
      marginLeft: '24px',
      marginRight: '24px',
      marginBottom: '-8px',
    }),
    muiGridItem: css({
      marginTop: '24px',
      '&:first-child': {
        marginTop: '0px',
      },
      '&:not(:first-child)': {
        marginLeft: '16px',
      },
    }),

    infoText: css({
      paddingLeft: '8px',
    }),
  }));

export default useStyles;
