import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    infoDialog: css({
      '& .MuiDialog-paper': {
        [theme.breakpoints.down('sm')]: {
          background: '#F6F7FA',
        },
      },
    }),
    closeButton: css({
      display: 'flex',
      float: 'right',
      background: '#fff !important',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: '16px',
        top: '16px',
      },
    }),
    dialogContent: css({
      padding: '16px 16px 40px 16px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
    }),
    gridContent: css({
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    }),
    closeIcon: css({
      color: theme.palette.primary.main,
    }),
    title: css({
      paddingTop: '16px !important',
      fontSize: '24px !important',
      lineHeight: '32px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8px !important',
      },
    }),
    content: css({
      paddingTop: '12px !important',
      lineHeight: '24px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '24px !important',
      },
      '& p': {
        textAlign: 'left',
        margin: '0',
      },
    }),
    topAlign: css({
      [theme.breakpoints.down('sm')]: {
        display: 'block !important',
      },
    }),
  }));

export default useStyles;
