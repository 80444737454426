import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    headerContainer: css({
      [theme.breakpoints.down('sm')]: {
        display: 'contents !important',
      },
    }),
    headerImage: css({
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: '0 0 2.5rem 2.5rem',
      '@media (max-width:1120px)': {
        borderRadius: '0 0 0 0',
      },
    }),
    headerContentContainer: css({
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      paddingLeft: '144px',
      paddingRight: '144px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'sticky',
        top: '0',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '0px !important',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundColor: '#f6f7fa',
      },

      zIndex: 10,
    }),
    topMenuItemRight: css({
      color: '#151617',
      cursor: 'pointer',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    }),
    dskLogoHeader: css({
      color: '#ffffff',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '166px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '133px',
      },
    }),
    dskLogo: css({
      paddingTop: '25px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }),
    logoContainer: css({
      display: 'flex',
      justifyContent: 'space-between',
    }),
    hideLogoContainer: css({
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    }),
    languageIcon: css({
      position: 'relative',
      top: '5px',
      marginRight: '5px',
      fontSize: '1.2rem',
    }),
    dskCallCenter: css({
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '7px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }),
    callCenterContainer: css({
      width: '190px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '10px',
      height: '70px',
    }),
    callCenterContent: css({
      color: 'rgba(21, 22, 23, 0.6)',
    }),
    callCenterIcon: css({
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    }),
  }));

export default useStyles;
