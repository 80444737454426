import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    closeButton: css({
      backgroundColor: '#FFF !important',
      color: '#52AE30',
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: '24px',
        top: '24px',
      },
      '@media (max-width: 320px)': {
        right: '16px',
        top: '16px',
      },
    }),
    dialog: css({
      '& .MuiDialogContent-root': {
        padding: '32px',
        background: '#F6F7FA',
        maxWidth: '500px',
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(0.2),
      },
      '& .MuiDialog-paper': {
        backgroundColor: '#F6F7FA',
        borderRadius: '32px',
      },
    }),
    dialogContent: css({
      textAlign: 'initial',
      [theme.breakpoints.down('sm')]: {
        textAlign: '-webkit-center',
      },
    }),
    border: css({
      borderBottom: '1px solid #D0D8E8',
      marginTop: '32px',
      marginBottom: '16px',
    }),
    mobileDialog: css({
      '& .MuiDialogContent-root': {
        backgroundColor: '#F6F7FA',
        textAlign: '-moz-center',
        [theme.breakpoints.down('sm')]: {
          padding: '24px',
        },
      },
      '& .MuiDialog-paper': {
        backgroundColor: '#F6F7FA',
      },
    }),
    offerHeader: css({
      fontFamily: 'Squad',
      display: 'flex',
      marginTop: '14px',
      fontWeight: '800',
      fontSize: '32px',
      lineHeight: '40px',
      justifyContent: 'center',
      color: '#52AE30',
      [theme.breakpoints.down('sm')]: {
        marginTop: '72px',
      },
    }),
    offerText: css({
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '32px',
      color: '#151617',
      marginTop: '16px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '12px',
      },
    }),
    offerAmount: css({
      fontWeight: 700,
    }),
    rowCheck: css({
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '20px',
      maxWidth: '500px',
    }),
    checkIconWrapper: css({
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    checkList: css({
      color: '#151617',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px',
      textAlign: 'left',
      marginLeft: '16px',
    }),
    noteText: css({
      textAlign: 'center',
      color: '#151617',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      maxWidth: '500px',
    }),
    noteTextLink: css({
      display: 'block',
      fontSize: '16px',
      fontWeight: 600,
      color: '#52AE30',
      fontStyle: 'Italic',
      lineHeight: '24px',
      textDecoration: 'underline',
      textTransform: 'uppercase',
    }),
    pageLogo: css({
      position: 'absolute',
      left: 0,
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        '@media (min-height: 750px)': {
          maxWidth: '293px',
          height: '308px',
        },
      },
    }),
    campaignSection: css({
      backgroundColor: '#FFF',
      borderRadius: '16px',
      marginTop: '24px',
      padding: '8px 16px 8px 24px',
      display: 'flex',
      alignItems: 'center',
    }),
    campaignText: css({
      color: '#151617',
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontStyle: 'italic',
    }),
    infoIcon: css({
      width: '24px',
      height: '24px',
      '& path': {
        stroke: '#52AE30',
      },
    }),
  }));

export default useStyles;
