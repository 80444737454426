import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import useStyles from './process-description-dialog.styles';
import { useApplicationState } from '../../../providers/application/application.provider';
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';

const ProcessDescriptionModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    nomenclatures: { steps },
  } = useApplicationState();

  const classes = useStyles(steps.length);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDialogClose = () => {
    handleClose();
    setExpanded(false);
  };

  const progressBarSteps = steps.filter((step) => step.partOfStepsFlow);

  return (
    <Grid>
      <Dialog
        onClose={handleDialogClose}
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogContent className={classes.dialogContent}>
          <Fab
            size='medium'
            className={classes.closeButton}
            onClick={handleDialogClose}
            sx={{
              backgroundColor: '#FFFF !important',
              top: mobileView ? '16px' : '46px',
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </Fab>
          <Grid container spacing={0.2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} className={classes.stepsHeader}>
              <span>{t('steps-dialog.header-first-part')}</span>
              <span className={classes.stepsHeaderSecondPart}>
                {t('steps-dialog.header-second-part')}
              </span>
            </Grid>
          </Grid>
          <div className={classes.stepsDescription}>
            {progressBarSteps.map((item, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                className={classes.roundedAccordion}
                key={`panel${item.nameEN}`}
              >
                <AccordionSummary
                  className={classes.roundedAccordionSummary}
                  classes={{ expanded: classes.expanded }}
                  expandIcon={<ArrowDown style={{ color: '#52AE30' }} />}
                  key={`summary${item.nameEN}`}
                >
                  <Typography
                    className={
                      expanded === `panel${index}` ? classes.stepsTextExpanded : classes.stepsText
                    }
                  >
                    {`${index + 1}. ${item.name}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.roundedAccordionDetails}
                  key={`delais${item.nameEN}`}
                >
                  <Typography className={classes.stepsDescriptionText}>
                    {item.extendedInfo}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default ProcessDescriptionModal;
