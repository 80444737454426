import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useApplicationState } from '../../providers/application/application.provider';
import useStyles from './error.styles';
import ErrorIcon from '../../assets/images/error_icon.svg';
import HourglassIcon from '../../assets/images/hourglass.svg';

const Error = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorMessageTitle, errorMessageText, awaitingIdentificationData } = useApplicationState();

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid className={classes.gridItem} item xs={12}>
        {awaitingIdentificationData ? (
          <img src={HourglassIcon} alt='' />
        ) : (
          <img className={classes.errorIconSize} src={ErrorIcon} alt='' />
        )}
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {errorMessageTitle || t('error.error-title')}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography component='h6' variant='h6'>
          <Trans>{errorMessageText || t('error.error-subtitle')}</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Error;
