import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    dialogContent: css({
      marginTop: '0',
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'pre-line',
      },
    }),
    closeButton: css({
      position: 'absolute',
      right: '16px',
      top: '16px',
      background: 'transparent !important',
    }),
    closeIcon: css({
      color: theme.palette.primary.main,
    }),
  }));

export default useStyles;
