import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = ({ mobileHeight, ultraSmallHeight }) =>
  makeStyles((theme) => ({
    generalErrorContainer: css({
      height: '100vH',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      overflow: 'hidden',
    }),
    logoContainer: css({
      cursor: 'pointer',
      margin: '80px 50px 80px 0px',
      [theme.breakpoints.down('md')]: {
        margin: mobileHeight ? '20px 0px' : '80px 0px ',
        transform: mobileHeight ? 'scale(0.7)' : '',
      },
      [theme.breakpoints.down('sm')]: {
        margin: ultraSmallHeight ? '0px' : '20px 0px',

        transform: 'scale(0.7)',
      },
    }),
    generalErrorBgImage: css({
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      height: '650px',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '80%',
        margin: '0px auto',
      },
      [theme.breakpoints.down('sm')]: {
        height: '380px',
        '@media (max-width: 320px)': {
          height: '300px',
        },
      },
      [theme.breakpoints.up('xl')]: {
        height: '100%',
      },
    }),
    generalErrorMessageContainer: css({
      width: '100%',
      padding: '0px 24px',
      position: 'absolute',
      top: '65%',
      left: '50%',
      transform: 'translate(-50%, -35%)',
      [theme.breakpoints.down('md')]: {
        marginTop: mobileHeight ? '12px' : '',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: ultraSmallHeight ? '16px' : '',
      },
    }),
    generalErrorMessageTitle: css({
      color: '#52AE30',
      fontSize: '40px',
      fontWeight: 700,
      fontFamily: 'Squad',
      padding: '0px 16px',
      [theme.breakpoints.down('md')]: {
        fontSize: mobileHeight ? '24px' : '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: ultraSmallHeight ? '20px' : '24px',
        '@media (max-width: 320px)': {
          fontSize: '16px',
        },
      },
    }),
    generalErrorMessageSubTitle: css({
      color: '#52AE30',
      fontSize: '24px',
      fontWeight: 400,
      fontFamily: 'Squad',
      margin: '12px auto 0px auto',
      padding: '0px 16px',
      maxWidth: '554px',
      [theme.breakpoints.down('md')]: {
        margin: mobileHeight ? '0px auto' : '12px auto 0px auto',
        fontSize: mobileHeight ? '16px' : '24px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
        fontSize: ultraSmallHeight ? '14px' : '16px',
        '@media (max-width: 320px)': {
          fontSize: '14px',
          marginBottom: '40px',
        },
      },
    }),
  }));
export default useStyles;
