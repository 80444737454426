import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    buttonContainer: css({
      maxWidth: '500px',
      margin: '80px auto 64px auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: '48px',
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#fff',
        borderRadius: '24px',
        marginTop: '32px',
      },
      '@media (max-width: 333px)': {
        padding: '6px',
      },
    }),
    button: css({
      cursor: 'pointer',
      width: '100%',
      height: '64px',
      backgroundColor: '#F6F7FA',
      border: 'none',
      borderRadius: '32px',
      padding: '12px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        marginBottom: '0px',
      },
      '@media (max-width: 333px)': {
        padding: '8px 0px',
      },
    }),
    iconContainer: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '40px',
      backgroundColor: 'rgba(82, 174, 48, 0.10)',
    }),
    secondaryBackground: css({
      backgroundColor: 'rgba(255, 102, 0, 0.10)',
    }),
    labelInfo: css({
      marginLeft: '8px',
      color: '#151617',
      fontSize: '18px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      '@media (max-width: 333px)': {
        fontSize: '12px',
        marginLeft: '5px',
      },
    }),
    officeNote: css({
      fontFamily: 'Roboto',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '16px',
      textTransform: 'uppercase',
      marginLeft: '8px',
      color: '#FF6600',
      '@media (max-width: 333px)': {
        marginLeft: '5px',
      },
    }),
    arrowRightSecondaryColor: css({
      '& path': {
        stroke: '#FF6600',
      },
    }),
  }));

export default useStyles;
