import React from 'react';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './general-error';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import GeneralErrorBackground from '../../assets/images/503.svg';

const GeneralError = () => {
  const mobileHeight = useMediaQuery('(max-height: 430px)');
  const ultraSmallHeight = useMediaQuery('(max-height: 330px)');
  const classes = useStyles({ mobileHeight, ultraSmallHeight });
  const { t } = useTranslation();

  return (
    <div className={classes.generalErrorContainer}>
      <Grid className={classes.logoContainer} onClick={() => window.location.reload(true)}>
        <Logo />
      </Grid>
      <div
        className={classes.generalErrorBgImage}
        style={{
          backgroundImage: `url(${GeneralErrorBackground})`,
        }}
      >
        <div className={classes.generalErrorMessageContainer}>
          <Typography className={classes.generalErrorMessageTitle}>
            {t('general-error-message-title')}
          </Typography>
          <Typography className={classes.generalErrorMessageSubTitle}>
            <Trans> {t('general-error-message-subtitle')}</Trans>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default GeneralError;
