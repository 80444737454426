import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = (showPromoOffer) =>
  makeStyles((theme) => ({
    ourOfferCard: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: showPromoOffer ? 'space-evenly' : 'center',
      padding: showPromoOffer ? '16px 24px' : '16px',
      alignItems: 'center',
      maxWidth: '382px',
      height: '89px',
      background: '#52AE30',
      boxShadow: '0px 8px 24px rgba(66, 139, 38, 0.24)',
      borderRadius: '16px',
      position: 'relative',
      margin: '0 auto 56px auto',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '268px',
        boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
        margin: '0 auto 24px auto',
        justifyContent: showPromoOffer ? 'space-between' : 'center',
      },
    }),
    ourOfferText: css({
      fontWeight: '900',
      lineHeight: '31px',
      color: '#FFFFFF',
      textAlign: 'center',
      position: 'relative',
      left: showPromoOffer ? '0px' : '6px',
      fontSize: showPromoOffer ? '24px' : '22px',
    }),
    ourOfferButton: css({
      position: 'relative',
      top: '4px',
      left: '9px',
    }),
  }));

export default useStyles;
