import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation, Trans } from 'react-i18next';
import useStyles from './request.styles';
import ClientDataStep from '../../components/request/client-data-step/client-data-step.component';
import IdentificationStep from '../../components/request/identification-step/identification-step.component';
import GeneralTermsStep from '../../components/request/general-terms-step/general-terms-step.component';
import AdditionalDataStep from '../../components/request/additional-data-step/additional-data-step.component';
import DocumentsDataStep from '../../components/request/documents-data-step/documents-data-step.component';
import RequestSuccess from '../../components/request/request-success/request-success.component';
import { useApplicationState } from '../../providers/application/application.provider';
import RequestInfo from '../../components/request/request-info/request-info.component';
import ReloadRequiredPrompt from '../../components/reload-required-prompt/reload-required-prompt.component';
import EntryStep from '../../components/request/entry-step/entry-step.component';
import PackagesDataStep from '../../components/request/packages-data-step/packages-data-step.component';
import DebitCardStep from '../../components/request/debit-card-step/debit-card-step.component';

function getStepContent(steps, step, formRefs, backRefs) {
  const stepIndex = steps.map((item) => item.nameEN).indexOf(step);

  switch (step) {
    case 'ClientData':
      return <ClientDataStep formRef={formRefs[stepIndex]} referrerUrl={document.referrer} />;
    case 'GeneralTerms':
      return <GeneralTermsStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'Identification':
      return <IdentificationStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'AdditionalData':
      return <AdditionalDataStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'DebitCard':
      return <DebitCardStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'DocumentsData':
      return <DocumentsDataStep formRef={formRefs[stepIndex]} />;
    case 'Packages':
      return <PackagesDataStep formRef={formRefs[stepIndex]} />;
    default:
      return '';
  }
}

function Request() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const {
    activeStep,
    loading,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    isSessionStarted,
    loadingDocuments,
    showDocumentsVerificationData,
    isFromBranch,
    showEntryStep,
    packageId,
    nomenclatures: { steps },
  } = useApplicationState();

  useEffect(() => {
    // Note: Scroll to top when change active step or info step,
    // showDocumentsVerificationData opens more info on DocumentsData page
    if (!showDocumentsVerificationData) {
      window.scrollTo(0, 0);
    }

    // Note: Triggers page change for hotjar heatmaps
    if (isSessionStarted && typeof window !== 'undefined' && typeof window.hj === 'function') {
      let pageName;
      if (confirmationEmailPending) {
        pageName = '/ConfirmationEmailPending';
      } else if (resumeEmailPending) {
        pageName = '/ResumeEmailPending';
      } else if (requestCannotContinue) {
        pageName = '/RequestCannotContinue';
      } else if (showDocumentsVerificationData) {
        pageName = '/DocumentsData_Signing';
      } else if (showEntryStep) {
        pageName = '/EntryStep';
      } else if (!activeStep || activeStep === 'ClientData') {
        pageName = isFromBranch ? '/ClientData_Branch' : '/ClientData_Online';
      } else if (!loadingDocuments && activeStep !== steps[steps.length - 1].nameEN) {
        pageName = `/${activeStep}`;
      }

      if (pageName) {
        window.hj('stateChange', pageName);
      }
    }
  }, [
    activeStep,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    showDocumentsVerificationData,
    isFromBranch,
    showEntryStep,
    isSessionStarted,
    loadingDocuments,
  ]);

  const progressBarSteps = steps.filter((step) => step.partOfStepsFlow);
  const currentStep = progressBarSteps.find(
    (step) => step.nameEN === (activeStep || steps[0].nameEN)
  );
  const formRefs = progressBarSteps.map(() => React.createRef());
  const backRefs = progressBarSteps.map(() => React.createRef());
  const currentStepIndex = progressBarSteps
    ? progressBarSteps.map((item) => item.nameEN).indexOf(currentStep && currentStep.nameEN)
    : 0;
  const isFinalStepForUser =
    progressBarSteps.length && activeStep === progressBarSteps[progressBarSteps.length - 1].nameEN;

  const handleNext = () => {
    const formRef = formRefs[currentStepIndex];
    if (formRef && formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  if (!isSessionStarted) {
    history.push(`/${location.search}`);
    return <div />;
  }

  if (activeStep && activeStep === steps[steps.length - 1].nameEN) {
    return (
      <div className={classes.root}>
        <RequestSuccess />
      </div>
    );
  }

  if (requestCannotContinue || confirmationEmailPending || resumeEmailPending) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  if (showEntryStep) {
    return (
      <div className={classes.root}>
        <EntryStep />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {isFromBranch && currentStep.nameEN === steps[0].nameEN ? (
        <div className={classes.employeeIdentifierPush} />
      ) : (
        ''
      )}
      {!loadingDocuments && (
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 900,
              fontSize: '28px',
              lineHeight: '32px',
              color: '#151617',
              marginBottom: '12px',
            }}
          >
            {currentStep.name}
          </Box>
          {currentStep.description && (
            <Box
              style={{
                alignItems: 'center',
                textAlign: 'center',
                color: '#151617',
                marginBottom: '32px',
                whiteSpace: 'pre-line',
              }}
            >
              <Trans>{currentStep.description}</Trans>
            </Box>
          )}
        </Grid>
      )}
      {getStepContent(steps, currentStep.nameEN, formRefs, backRefs)}
      {!loadingDocuments && (
        <div className={classes.buttonOuterWrapper}>
          <Button
            id={
              !showDocumentsVerificationData && isFinalStepForUser
                ? `btnForward_ShowDocumentsVerificationData`
                : `btnForward_${currentStep.nameEN}`
            }
            variant='contained'
            color='primary'
            onClick={handleNext}
            className={isFinalStepForUser ? classes.largeButton : classes.button}
            disabled={loading || (!packageId && isFromBranch && activeStep === 'Packages')}
          >
            {showDocumentsVerificationData && isFinalStepForUser
              ? t('general.complete')
              : currentStep.submitBtnLabel}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      )}

      <ReloadRequiredPrompt />
    </div>
  );
}

export default Request;
