/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useStyles from './entry-step.styles';
import { useApplicationDispatch } from '../../../providers/application/application.provider';
import {
  getStepsByProcessAsync,
  setIsFromBranch,
  checkGeneralErrorAsync,
} from '../../../providers/application/application.actions';
import DskLogo from '../../../assets/images/dsk_logo.png';
import OtpLogoMobile from '../../../assets/images/otp-logo-mobile.png';
import OtpLogoTablet from '../../../assets/images/otp-logo-tablet.png';
import EntryStepButtons from '../entry-step-buttons/entry-step-buttons.component';
import InfoDialog from '../../info-dialog/info-dialog.component';
import HourglassIcon from '../../../assets/images/hourglass.svg';

const EntryStep = () => {
  const dispatch = useApplicationDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  const [showCapitalAccountMessage, setShowCapitalAccountMessage] = useState(false);

  const combineIsFromBranch = (isFromBranch) => {
    dispatch(setIsFromBranch(isFromBranch, false));
    getStepsByProcessAsync(isFromBranch, dispatch);
  };

  const handleButtonClick = (isFromBranch, isCapitalAccount) => {
    if (isCapitalAccount) {
      setShowCapitalAccountMessage(true);
    } else {
      checkGeneralErrorAsync(dispatch, () => combineIsFromBranch(isFromBranch));
    }
  };

  const handleLogoClick = () => {
    history.push(`/`);
  };

  const capitalAccountModalClose = () => setShowCapitalAccountMessage(false);

  return (
    <Grid container className={classes.contentContainer}>
      <Grid item xs={12} className={classes.dskLogoMobile} onClick={handleLogoClick}>
        <img src={DskLogo} style={{ width: '160px' }} alt='logo' />
      </Grid>
      {!desktop && (
        <div className={classes.otpLogoContainer}>
          <img src={mobile ? OtpLogoMobile : OtpLogoTablet} alt='logo-mobile' />
        </div>
      )}
      <Grid item xs={12} style={{ paddingLeft: '0' }}>
        <Box className={classes.header}>
          <Trans>{t('entry-step.header-part1')}</Trans>
          <span className={classes.greenText}>{t('entry-step.header-part2')}</span>
          <Trans> {t('entry-step.header-part3')}</Trans>
        </Box>
        <Box className={classes.subheader}>{t('entry-step.subheader')}</Box>
      </Grid>
      <EntryStepButtons handleButtonClick={handleButtonClick} />
      <InfoDialog
        open={showCapitalAccountMessage}
        title={t('entry-step.capital-account-modal-message')}
        handleClose={capitalAccountModalClose}
        icon={HourglassIcon}
      />
    </Grid>
  );
};

export default EntryStep;
