/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import Grid from '@mui/material/Grid';
import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { Trans } from 'react-i18next';
import useStyles from './loader.styles';
import LoaderGif from '../../../assets/gifs/loader.gif';

const Loader = forwardRef((p, ref) => {
  const { title, message } = p;

  const classes = useStyles();

  return (
    <Grid>
      <Box className={classes.loaderStyle}>
        <Grid item xs={12} className={classes.titleStyle}>
          {title}
        </Grid>
        <Grid>
          <img src={LoaderGif} alt='loader' className={classes.imageStyle} />
        </Grid>
      </Box>
      <Grid item xs={12} className={classes.messageStyle}>
        <Trans>{message}</Trans>
      </Grid>
    </Grid>
  );
});

export default Loader;
