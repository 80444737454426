import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setIsFromBranch,
  setUtmMedium,
  startSessionAsync,
} from '../../providers/application/application.actions';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../providers/application/application.provider';

const StartSession = () => {
  const history = useHistory();
  const location = useLocation();

  const { utmMedium, adUtmSources } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const onSessionSuccess = (activeStep, steps, requestCannotContinue) => {
    const queryParams = new URLSearchParams(location.search);
    const utmMediumParam = queryParams.get('utm_medium');
    const utmSourceParam = queryParams.get('utm_source');
    const utmCampaign = queryParams.get('utm_campaign');

    if ((utmMediumParam === 'pyramids' || utmCampaign === 'referrer') && !utmMedium) {
      dispatch(setUtmMedium({ utmMedium: utmMediumParam }));
    }

    if (adUtmSources.indexOf(utmSourceParam) === -1 && activeStep === steps[0].nameEN) {
      dispatch(setIsFromBranch(false, !requestCannotContinue));
    }

    history.push('/Request');
  };

  useEffect(() => {
    startSessionAsync(location.search, dispatch, onSessionSuccess);
  }, []);

  return <div />;
};

export default StartSession;
