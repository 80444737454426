import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    packageDialog: css({
      maxWidth: '650px',
      margin: '0 auto',
      '& .MuiDialog-paper': {
        background: '#ECEFF6',
      },
    }),
    closeButton: css({
      position: 'absolute',
      right: '36px',
      top: '36px',
      background: '#fff !important',
      [theme.breakpoints.down('sm')]: {
        right: '16px',
        top: '16px',
      },
    }),
    dialogContent: css({
      padding: '40px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '28px',
        paddingLeft: '28px',
      },
    }),
    gridContent: css({
      textAlign: 'center',
      marginTop: '25px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    }),
    closeIcon: css({
      color: theme.palette.primary.main,
    }),
    packageTermsContainer: css({
      display: 'flex',
      textAlign: 'left',
      lineHeight: '24px',
      marginTop: '30px',
      '& p': {
        margin: '0px 0px 0px 8px',
        color: '#104E88',
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
          margin: '0px 0px 0px 16px',
        },
      },
      '& ul': {
        margin: '30px 0px 0px 0px',
        '@media (max-width: 320px)': {
          padding: 0,
        },
      },
    }),
    packageConsentText: css({
      textAlign: 'left',
      fontWeight: 600,
      marginTop: '24px',
      lineHeight: '24px',
    }),
    packageAdditionalInfo: css({
      fontSize: '14px',
      lineHeight: '24px',
      color: '#104E88',
      textAlign: 'left',
    }),
    packageTitle: css({
      marginTop: '10px',
      fontSize: '32px',
      fontWeight: 800,
      lineHeight: '40px',
      color: '#104E88',
      fontFamily: 'Squad',
    }),
    packagePrice: css({
      color: '#52AE30',
      fontWeight: 700,
      lineHeight: '24px',
    }),
  }));

export default useStyles;
