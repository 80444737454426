import React from 'react';
import useStyles from './investor-information-bulletin.styles';

function InvestorInformationBulletin() {
  const classes = useStyles();
  return (
    <div className='consent'>
      <div>
        <table className={classes.tableBorders}>
          <thead />
          <tbody>
            <tr className={classes.tableBorders}>
              <td colSpan='2'>
                <b>Основна информация относно защитата на влоговете</b>
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>Влоговете в „Банка ДСК“ АД са защитени от:</td>
              <td className={classes.tableBorders}>
                Фонд за гарантиране на влоговете в банките (ФГВБ)
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>Гарантиран размер:</td>
              <td className={classes.tableBorders}>196 000 лв. на един вложител в една банка</td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>Ако притежавате повече влогове в една банка:</td>
              <td className={classes.tableBorders}>
                Всички Ваши влогове в същата банка се &quot;сумират&quot; и за общата сума се
                прилага гарантираният размер – 196 000 лв. <sup>1</sup>
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>
                Ако притежавате съвместен влог заедно с друго лице (лица):
              </td>
              <td className={classes.tableBorders}>
                Гарантираният размер – 196 000 лв., се прилага за всеки отделен вложител{' '}
                <sup>2</sup>
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>
                Срок за изплащане на гарантирани суми в случай на неплатежоспособност на банката:
              </td>
              <td className={classes.tableBorders}>
                7 работни дни <sup>3</sup>
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>
                Парична единица, използвана при изплащане на гарантирани суми:
              </td>
              <td className={classes.tableBorders}>
                Гарантираните суми по влоговете се изплащат в български левове.
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>За контакт:</td>
              <td className={classes.tableBorders}>
                Фонд за гарантиране на влоговете в банките (ФГВБ)
                <br />
                Адрес: ул. Владайска № 27, гр. София п.к. 1606
                <br />
                тел: +359 2 953 1217, факс: +359 2 952 1100,
                <br />
                e-mail: contact@dif.bg <br />
                URL: http://dif.bg
              </td>
            </tr>
            <tr className={classes.tableBorders}>
              <td className={classes.tableBorders}>За повече информация:</td>
              <td className={classes.tableBorders}>www.dif.bg</td>
            </tr>
          </tbody>
        </table>
        <p className={classes.justifyText}>
          <sup>1</sup> Ако даден влог е неналичен, тъй като банка не е в състояние да посрещне
          финансовите си задължения, ФГВБ изплаща влоговете на вложителите. Максималният размер,
          който може да бъде изплатен, е 196 000 лв. на банка. Това означава, че всички влогове в
          една банка се сумират, за да се определи размерът на гаранцията. Например, ако един
          вложител има спестовна сметка, по която има 180 000 лв., и разплащателна сметка, по която
          има 40 000 лв., на този вложител ще бъдат изплатени само 196 000 лв.
        </p>
        <p className={classes.justifyText}>
          <sup>2</sup> При съвместни влогове ограничението – 196 000 лв., важи за всеки вложител
          поотделно. Повече информация можете да получите на уебсайта на ФГВБ: www.dif.bg.
        </p>
        <p className={classes.justifyText}>
          <sup>3</sup> Изплащане на гарантирани суми <br />
          <b>Задължената схема за гарантиране на влоговете е: </b>
          <br />
          Фонд за гарантиране на влоговете в банките (ФГВБ) <br />
          1606 София, ул. Владайска № 27 <br />
          тел: +359 2 953 1217, факс: +359 2 952 1100 <br />
          e-mail:{' '}
          <a className={classes.hyperlinkStyle} href='mailto:contact@dif.bg'>
            contact@dif.bg
          </a>
          , URL:{' '}
          <a
            className={classes.hyperlinkStyle}
            href='http://dif.bg'
            target='_blank'
            rel='noopener noreferrer'
          >
            http://dif.bg
          </a>
        </p>
        <p className={classes.justifyText}>
          ФГВБ ще започне изплащане на влоговете Ви в размер до 196 000 лв. в срок най-късно до 7
          работни дни от датата на издаване на акт по чл. 20, ал. 1 от Закона за гарантиране на
          влоговете в банките.
        </p>
        <p className={classes.textAlignLeft}>
          Друга важна информация <br />
          По принцип всички вложители, независимо дали са физически, или юридически лица, са
          защитени чрез схемите за гарантиране на влоговете. Изключенията за определени влогове са
          посочени на уебсайта на Задължената схема за гарантиране на влоговете. <br />
          При искане от Ваша страна „Банка ДСК“ АД ще Ви информира дали определени продукти са
          гарантирани, или не.
        </p>
      </div>
    </div>
  );
}

export default InvestorInformationBulletin;
