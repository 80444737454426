import React from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { Card } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import { Trans, useTranslation } from 'react-i18next';
import BoricaLogo from '../../../assets/images/borica_logo.png';

import useStyles from './identification-step.styles';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveIdentificationDataAsync,
  setAcceptedOnlineIdentificationConsent,
  setAcceptedBoricaConsent,
} from '../../../providers/application/application.actions';
import { ReactComponent as CheckboxIconChecked } from '../../../assets/images/checbox-checked.svg';
import { ReactComponent as CheckboxIconUnchecked } from '../../../assets/images/checbox-unchecked.svg';
import Info from '../../info/info.component';

const IdentificationStep = ({ formRef }) => {
  const { handleSubmit, errors, getValues, register } = useForm();
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    acceptedBoricaConsent,
    acceptedOnlineIdentificationConsent,
    loading,
    nomenclatures: { boricaIdentificationTermsUrl, boricaPersonalDataTermsUrl },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const handleBoricaConsentChange = () => {
    dispatch(setAcceptedBoricaConsent(!acceptedBoricaConsent));
  };
  const handleOnlineIdentificationChange = () => {
    dispatch(setAcceptedOnlineIdentificationConsent(!acceptedOnlineIdentificationConsent));
  };

  const onSubmit = () => {
    const data = getValues();
    saveIdentificationDataAsync(data, dispatch);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container>
        <Grid item xs={12} className={classes.muiGridItem}>
          <Card className={classes.boricaCard}>
            <img
              style={{
                height: 'auto',
                maxHeight: 205,
                width: 'auto',
                maxWidth: 205,
              }}
              src={BoricaLogo}
              alt='borica logo'
            />
          </Card>
          <Card
            sx={{
              borderRadius: '24px',
              background: '#EEF7EA',
              color: '#52AE30',
              border: 'none',
              padding: '16px',
              display: 'flex',
              marginTop: '32px',
              lineHeight: '24px',
              boxShadow: 'none',
            }}
          >
            <Grid className={classes.identificationContainer}>
              <Grid>
                <PhoneAndroidIcon />
              </Grid>
              <Grid className={classes.infoText}>{t('identification-step.card')}</Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} className={`${classes.lineHeightSection} ${classes.muiGridItem}`}>
          <Grid className={classes.identificationContainer}>
            <Grid style={{ color: '#52AE30' }}>
              <PhoneAndroidIcon />
            </Grid>
            <Grid className={classes.infoText}>{t('identification-step.document-info-text')}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.muiGridItem}>
          <Grid className={classes.identificationContainer}>
            <Grid style={{ color: '#52AE30' }}>
              <WbIncandescentOutlinedIcon
                style={{ transform: 'rotate(180deg)', width: 27, height: 29 }}
              />
            </Grid>
            <Grid className={classes.infoText}>
              {t('identification-step.camera-info-text')}
              <Info content={t('identification-step-second.info-content')} smallIcon topAlign />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${classes.border} ${classes.muiGridItem}`} />
        <Grid item xs={12} className={`${classes.lineHeightSection} ${classes.muiGridItem}`}>
          <FormControlLabel
            control={
              <Checkbox
                name='acceptedBoricaConsent'
                checked={!!acceptedBoricaConsent}
                disabled={loading}
                onClick={handleBoricaConsentChange}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.checkbox-field.required'),
                  },
                })}
                icon={<CheckboxIconUnchecked />}
                checkedIcon={<CheckboxIconChecked />}
              />
            }
            label={
              <span>
                <span>
                  {t('identification-step.online-consent-text')}
                  <a href={boricaIdentificationTermsUrl} target='_blank' rel='noopener noreferrer'>
                    {t('identification-step.online-consent-link')}
                  </a>
                </span>
              </span>
            }
          />
          {errors.acceptedBoricaConsent && !acceptedBoricaConsent && (
            <p className='checkboxErrorMessage'>{errors.acceptedBoricaConsent.message}</p>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.lineHeightSection} ${classes.marginCheckbox} ${classes.muiGridItem}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                name='acceptedOnlineIdentificationConsent'
                checked={!!acceptedOnlineIdentificationConsent}
                onClick={handleOnlineIdentificationChange}
                disabled={loading}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.checkbox-field.required'),
                  },
                })}
                icon={<CheckboxIconUnchecked />}
                checkedIcon={<CheckboxIconChecked />}
              />
            }
            label={
              <span>
                {t('identification-step.borika-consent-text1')}
                <a href={boricaPersonalDataTermsUrl} target='_blank' rel='noopener noreferrer'>
                  <Trans> {t('identification-step.borika-consent-link')}</Trans>
                </a>
                {t('identification-step.borika-consent-text2')}
              </span>
            }
          />
          {errors.acceptedOnlineIdentificationConsent && !acceptedOnlineIdentificationConsent && (
            <p className='checkboxErrorMessage'>
              {errors.acceptedOnlineIdentificationConsent.message}
            </p>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default IdentificationStep;
