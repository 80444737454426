import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    loaderStyle: css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#151617',
      marginBottom: '78px',
      marginTop: '70px',
    }),
    titleStyle: css({
      marginBottom: '78px',
    }),
    imageStyle: css({
      width: '130px',
      height: '130px',
    }),
    messageStyle: css({
      textAlign: 'center',
      paddingBottom: '113px',
      lineHeight: '34px',
      fontSize: '18px',
      fontWeight: 400,
      color: '#151617',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '250px',
      },
    }),
  }));

export default useStyles;
