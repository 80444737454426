import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../header/header.component';
import Footer from '../footer/footer.component';
import useStyles from './layout.styles';
import { useApplicationState } from '../../providers/application/application.provider';
import SideImage from '../side-image/side-image';
import OfferCard from '../request/offer-card/offer-card.component';
import GeneralError from '../general-error/general-error.component';

const Layout = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    activeStep,
    images,
    showEntryStep,
    defaultSideImageUrl,
    loadingSessionData,
    showGeneralError,
    nomenclatures: { steps },
  } = useApplicationState();

  const successScreen = activeStep && activeStep === steps[steps.length - 1].nameEN;
  const classes = useStyles(successScreen);

  const currentStep = steps.find((step) => step.nameEN === (activeStep || steps[0].nameEN));

  const setSideImage = () => {
    if (currentStep && !showEntryStep) {
      return images.find((img) => img.stepName === currentStep.nameEN).imageUrl;
    }
    return defaultSideImageUrl;
  };

  return (
    <div>
      {loadingSessionData ? (
        <Container className={classes.loaderContainer} maxWidth='sm'>
          <CircularProgress className={classes.loader} />
        </Container>
      ) : (
        ''
      )}
      {showGeneralError ? (
        <GeneralError />
      ) : (
        <Container
          maxWidth='xl'
          className={`${classes.mainContainer} ${loadingSessionData ? classes.displayNone : ''}`}
        >
          <Header />
          <Grid
            className={`${classes.contentContainer} ${
              showEntryStep && classes.entryStepContentContainer
            }`}
            container
            spacing={mobile ? 8 : 4}
          >
            <Grid item xs={12} md={6} className={classes.dataContainer}>
              {mobile ? <OfferCard /> : ''}
              <Paper
                className={`${classes.paper} ${showEntryStep ? classes.transparentBackground : ''}`}
                elevation={1}
              >
                {!mobile ? <OfferCard /> : ''}
                <Box className={classes.contentBox}>{children}</Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingTop: '0px !important',
                marginBottom: '32px',
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              {desktop ? (
                <Paper
                  className={classes.paper}
                  elevation={1}
                  style={{ padding: '32px', position: 'sticky', top: '108px' }}
                >
                  <SideImage src={setSideImage()} />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '72px',
                      background:
                        'linear-gradient(180deg, rgba(82, 174, 48, 0.7) 0%, #52AE30 100%)',
                      color: '#FFFFFF',
                      padding: '20px 32px',
                      borderRadius: '16px',
                      left: '72px',
                      right: '72px',
                    }}
                  >
                    {steps.length && activeStep === steps[steps.length - 1].nameEN ? (
                      <div className={classes.sidePanelHeader}>
                        {t('side-panel.header-request-success')}
                      </div>
                    ) : (
                      <>
                        <div className={classes.sidePanelHeader}>{t('side-panel.header')}</div>
                        <div>{t('side-panel.subheader')}</div>
                      </>
                    )}
                  </div>
                </Paper>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          {(desktop || !showEntryStep) && <Footer />}
        </Container>
      )}
    </div>
  );
};

export default Layout;
