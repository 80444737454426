import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useTheme, useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Trans } from 'react-i18next';
import useStyles from './info-dialog.styles';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const InfoDialog = ({ open, title, handleClose, topAlign, children, icon }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      className={classes.infoDialog}
    >
      <DialogContent className={`${classes.dialogContent} ${topAlign ? classes.topAlign : ''}`}>
        <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
        <Grid container className={classes.gridContent}>
          <Grid item xs={12}>
            <img src={icon} alt='' />
          </Grid>
          <Grid item xs={12}>
            <Typography component='h2' variant='h2' className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            <Trans>{children}</Trans>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoDialog;
