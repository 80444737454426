import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { Rating, Button } from '@mui/material';
import useStyles from './request-success.styles';
import Honeycomb from '../../../assets/images/honeycomb.svg';
import { saveRatingDataAsync } from '../../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import RatingDialog from '../../rating-dialog/rating-dialog';
import { ReactComponent as AppleStore } from '../../../assets/images/apple-store.svg';
import { ReactComponent as PlayStore } from '../../../assets/images/play-store.svg';
import DskBusiness from '../../../assets/images/dsk-business.png';

const RequestSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    rating,
    isFreeLancer,
    nomenclatures: { greenCertificateUrl, dskBusinessAppStoreUrl, dskBusinessGooglePlayUrl },
  } = useApplicationState();

  const dispatch = useApplicationDispatch();

  const [openNps, setOpenNps] = useState(false);

  const handleRatingChange = (e, newValue) => {
    saveRatingDataAsync({ rating: newValue }, dispatch);
    setOpenNps(true);
  };
  const handleRatingDialogClose = () => {
    setOpenNps(false);
  };

  const openAppStore = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Grid container spacing={2} className={classes.contentContainer}>
      <div>
        <Box className={classes.requestSuccessTitle}>{t('request-success.completed-request')}</Box>
        <Box className={classes.requestSuccessSubtitle}>
          {t('request-success.completed-request.subtitle')}
        </Box>
      </div>
      <div className={classes.combineTextContentContainer}>
        <Typography className={classes.requestSuccessTextLine1}>
          {isFreeLancer
            ? t('request-success.completed-request.freelancer-line1')
            : t('request-success.completed-request.line1')}
        </Typography>

        <Typography className={classes.requestSuccessTextLine2}>
          <Trans>{t('request-success.completed-request.line2')}</Trans>
        </Typography>
      </div>
      {greenCertificateUrl && (
        <Grid item xs={12} className={`${classes.greenSection} ${classes.boxPadding}`}>
          <Box className={classes.greenBox}>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              style={{ marginBottom: '14px', alignItems: 'center' }}
            >
              <img src={Honeycomb} alt='Honeycomb' height={96} width={96} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.greenTitle}>
                {t('request-success.green-title')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component='h6' variant='h6' color='#151617'>
                <Trans>{t('request-success.green-subtitle')}</Trans>
              </Typography>
            </Grid>
          </Box>
          <Box className={classes.greenButtonBox}>
            <Button className={classes.greenButton} href={greenCertificateUrl} target='_blank'>
              {t('request-success.green-button-title')}
            </Button>
          </Box>
        </Grid>
      )}
      <Grid className={classes.businessAppStore}>
        <div className={classes.businessAppStoreRows}>
          <div className={classes.playStoreRow}>
            <Typography className={classes.appStoreHeader}>
              {t('request-success.appStore-header')}
            </Typography>
            <Grid
              onClick={() => openAppStore(dskBusinessAppStoreUrl)}
              className={classes.playStoreButtonsContainer}
              style={{
                marginBottom: '12px',
              }}
            >
              <AppleStore />
            </Grid>
            <Grid
              onClick={() => openAppStore(dskBusinessGooglePlayUrl)}
              className={classes.playStoreButtonsContainer}
            >
              <PlayStore />
            </Grid>
          </div>
          <div className={classes.dskBusinessImageContainer}>
            <img alt='dsk-business' style={{ width: '100%' }} src={DskBusiness} />
          </div>
        </div>
      </Grid>
      <Grid className={classes.ratingSection}>
        <Grid item xs={12}>
          <Typography component='h6' variant='h6' color='#52AE30' fontWeight='600'>
            {t('request-success.rating-title')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.ratingIconBox}>
          <Rating
            className={classes.ratingIcons}
            name='rating'
            value={rating}
            onChange={handleRatingChange}
          />
        </Grid>
      </Grid>
      <RatingDialog open={openNps} handleClose={handleRatingDialogClose} />
    </Grid>
  );
};

export default RequestSuccess;
