import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = (showThanksMessage) =>
  makeStyles((theme) => ({
    infoDialog: css({
      maxWidth: '550px',
      margin: '0 auto',
      '& .MuiDialog-paper': {
        position: 'relative',
        background: '#F6F7FA',
      },
    }),
    closeButton: css({
      position: 'absolute',
      right: '24px',
      top: '24px',
      background: '#fff !important',
    }),
    closeIcon: css({
      color: theme.palette.primary.main,
    }),
    dialogContent: css({
      padding: '40px',
      '@media (max-width: 320px)': {
        paddingRight: '25px',
        paddingLeft: '25px',
      },
    }),
    gridContent: css({
      marginTop: '36px',
      textAlign: 'center',
      pointerEvents: showThanksMessage ? 'none' : 'initial',
      filter: showThanksMessage ? 'blur(3px)' : 'unset',
      opacity: showThanksMessage ? '0.2' : 'initial',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    }),
    title: css({
      fontSize: '30px',
      fontWeight: 900,
      color: '#52AE30',
    }),
    question: css({
      textAlign: 'left',
      fontSize: '18px',
      color: '#7D828B',
      marginTop: '8px',
    }),
    ratingSection: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      padding: '16px 32px',
      width: '100%',
      left: '0px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
      borderRadius: '27px',
      flex: 'none',
      order: '1',
      flexGrow: '0',
      margin: '24px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px',
      },
    }),
    ratingIcons: css({
      position: 'static',
      width: '229px',
      height: '33.4px',
      left: '13.5px',
      top: '40px',
      flex: 'none',
      order: '0',
      flexGrow: '0',
      marginTop: '8px',
      fontSize: '33.4px',
    }),
    thanksMessage: css({
      zIndex: 999,
      width: '100%',
      color: '#52AE30',
      textAlign: 'center',
      position: showThanksMessage ? 'absolute' : 'initial',
      top: showThanksMessage ? '50%' : 'initial',
      left: showThanksMessage ? '50%' : 'initial',
      transform: showThanksMessage ? 'translate(-50%, -50%)' : 'initial',
      ' & p': {
        fontSize: '33px',
        fontWeight: 900,
      },
    }),
  }));

export default useStyles;
