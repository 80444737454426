import React, { useEffect, useImperativeHandle, createRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import VerificationCode from '../validation-code/validation-code.component';
import useStyles from './general-terms-step.styles';
import {
  saveGeneralTermsDataAsync,
  generateVerificationCodeAsync,
  setSmsVerificationCode,
} from '../../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import mobileCodeImage from '../../../assets/images/mobile-code.png';

const GeneralTermsStep = ({ formRef, backRef }) => {
  const { reset, errors, getValues, setError, clearErrors } = useForm();
  const { t } = useTranslation();

  const {
    clientIdentifier,
    generatedVerificationCode,
    mobilePhoneConfirmed,
    verificationCodeValidityInMinutes,
    wrongSmsVerificationCode,
    maxSmsVerificationCodeAttemptsReached,
    expiredSmsVerificationCode,
    generateSmsVerificationCodeLoading,
    shouldGenerateVerificationCode,
    companyName,
    smsVerificationCode,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isSuccessfullyRegenerated, setIsSuccessfullyRegenerated] = React.useState(false);
  const ref = createRef();

  const classes = useStyles();

  const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (ref.current.validateVerificationCode(smsVerificationCode))
      saveGeneralTermsDataAsync({ smsVerificationCode }, dispatch);
  };

  const generateNewCode = () => {
    reset({ smsVerificationCode: '' });
    generateVerificationCodeAsync(null, dispatch, (successfullyRegenerated) =>
      setIsSuccessfullyRegenerated(successfullyRegenerated)
    );
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return getValues();
    },
  }));

  useEffect(() => {
    if (shouldGenerateVerificationCode) {
      generateNewCode();
    }
  }, [shouldGenerateVerificationCode]);

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <Grid item xs={12} className={classes.generalTermsContainer}>
        <Box margin='normal' style={{ textAlign: 'center', marginTop: '12px' }}>
          {t('general-terms-step.sms-verification-code-was-send', {
            verificationCodeValidityInMinutes,
          })}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
            paddingBottom: '24px',
          }}
        >
          <img src={mobileCodeImage} alt='mobile-code' />
        </Box>
        <Card
          sx={{
            borderRadius: '24px',
            background: '#EEF7EA',
            color: '#52AE30',
            border: 'none',
            padding: '16px 28px',
            textAlign: 'center',
            fontSize: '14px',
          }}
          variant='outlined'
        >
          <div style={{ fontWeight: '600' }}>
            {t('client-data-step.client-identifier')}: {clientIdentifier}
          </div>
          <div style={{ fontWeight: '600' }}>{companyName}</div>
          <div style={{ fontStyle: 'italic', marginTop: '8px' }}>
            {t('general-terms-step.data-extracted-from-external-registers')}
          </div>
        </Card>
        <Box
          margin='normal'
          style={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: '700',
            color: '#1B1F27',
            marginTop: '24px',
            marginBottom: '16px',
          }}
        >
          {t('general-terms-step.sms-verification-label')}
        </Box>
        <VerificationCode
          propertyName='smsVerificationCode'
          numberOfDigits={6}
          errors={errors}
          disabled={mobilePhoneConfirmed || generateSmsVerificationCodeLoading}
          verificationCode={smsVerificationCode}
          setError={setError}
          clearErrors={clearErrors}
          setVerificationCode={setSmsVerificationCode}
          hasError={
            !!wrongSmsVerificationCode ||
            !!expiredSmsVerificationCode ||
            !!maxSmsVerificationCodeAttemptsReached
          }
          errorMsg={
            (wrongSmsVerificationCode && (
              <Trans>{t('validation.wrong-sms-verification-code')}</Trans>
            )) ||
            (expiredSmsVerificationCode && (
              <Trans>{t('validation.expired-sms-verification-code')}</Trans>
            )) ||
            (maxSmsVerificationCodeAttemptsReached && (
              <Trans>{t('validation.max-sms-verification-code-attempts-reached')}</Trans>
            ))
          }
          ref={ref}
          disabledGenerateCode={mobilePhoneConfirmed || generateSmsVerificationCodeLoading}
          showLinkToRegenerateCode
          regenarateAction={generateNewCode}
          generateVerificationCodeLoading={generateSmsVerificationCodeLoading}
          isSuccessfullyRegenerated={isSuccessfullyRegenerated}
          buttonTitle={t('general.generate-code-button')}
        />
        {generatedVerificationCode && (
          <div className={classes.generatedCode}>{generatedVerificationCode}</div>
        )}
      </Grid>
    </form>
  );
};

export default GeneralTermsStep;
