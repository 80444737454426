/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Popper from '@mui/material/Popper';
import useStyles from './custom-popper.styles';

const CustomPopper = (props) => {
  const classes = useStyles();

  return <Popper {...props} className={classes.root} />;
};

export default CustomPopper;
