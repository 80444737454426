import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    infoIcon: css({
      color: '#1B82E3',
      fontSize: '1.25rem',
      cursor: 'default',
    }),
    small: css({
      fontSize: '1rem !important',
      position: 'relative',
      top: '2px',
    }),
    desktopTitle: css({
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    }),
  }));

export default useStyles;
