import { useMediaQuery, Grid, CircularProgress, Button } from '@mui/material';
import React, { useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDigitInput from 'react-digit-input';
import { useApplicationDispatch } from '../../../providers/application/application.provider';
import useStyles from './validation-code.styles';

const VerificationCode = forwardRef((p, ref) => {
  const {
    propertyName,
    disabled,
    errors,
    verificationCode,
    setError,
    clearErrors,
    hasError,
    errorMsg,
    setVerificationCode,
    buttonTitle,
    disabledGenerateCode,
    regenarateAction,
    generateVerificationCodeLoading,
    isSuccessfullyRegenerated,
    numberOfDigits,
    showLinkToRegenerateCode,
  } = p;
  const { t } = useTranslation();

  const classes = useStyles(numberOfDigits);
  const matches = useMediaQuery('(min-width:320px)');

  VerificationCode.displayName = 'VerificationCode';

  const dispatch = useApplicationDispatch();
  function validateVerificationCode(value) {
    if (value.replace(/[^0-9]+/g, '').length < numberOfDigits) {
      setError(`${propertyName}`, {
        type: 'manual',
        message: t('validation.field.required'),
      });

      return false;
    }

    clearErrors(`${propertyName}`);
    return true;
  }

  const handleVerificationCodeChange = (value) => {
    dispatch(setVerificationCode(value));
  };

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: numberOfDigits,
    value: verificationCode,
    onChange: handleVerificationCodeChange,
  });

  useImperativeHandle(ref, () => ({
    validateVerificationCode(value) {
      return validateVerificationCode(value);
    },
  }));

  return (
    <Grid className={classes.digitContainer}>
      <Grid item xs={12}>
        <div
          name={propertyName}
          className={`${classes.digitInputsWrapper} ${numberOfDigits > 6 ? classes.smallGap : ''}`}
        >
          {Array.from(Array(numberOfDigits), (e, i) => {
            return (
              <input
                key={i}
                {...digits[i]}
                inputMode='decimal'
                style={{ color: '#151617', fontWeight: 700 }}
                className={
                  numberOfDigits > 6 || !matches ? classes.smallDigitInput : classes.digitInput
                }
                disabled={disabled}
                autoComplete='disable-autocomplete'
              />
            );
          })}
        </div>
        {(hasError || !!errors[`${propertyName}`]) && (
          <p className='validationCodeErrorMessage'>
            {errors[`${propertyName}`] ? errors[`${propertyName}`].message : errorMsg}
          </p>
        )}
      </Grid>
      {showLinkToRegenerateCode && (
        <Grid item xs={12} className={classes.generateCodeBtnContainer}>
          <div className={classes.buttonOuterWrapper}>
            <div className={classes.buttonInnerWrapper}>
              <Button
                disabled={disabledGenerateCode}
                onClick={regenarateAction}
                className={classes.generateCodeButton}
              >
                {buttonTitle}
              </Button>
              {generateVerificationCodeLoading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
            {isSuccessfullyRegenerated && <span style={{ display: 'none' }} />}
          </div>
        </Grid>
      )}
    </Grid>
  );
});

export default VerificationCode;
