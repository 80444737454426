import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';
import useStyles from './offer-card.styles';
import { useApplicationState } from '../../../providers/application/application.provider';
import OfferModal from '../offer-dialog/offer-dialog.component';
import OurOfferButton from '../../../assets/images/round_button_32px.svg';
import { ReactComponent as PromoCampaign } from '../../../assets/images/promo-campaign.svg';

function OfferCard() {
  const { t } = useTranslation();
  const {
    activeStep,
    isFromBranch,
    showEntryStep,
    requestCannotContinue,
    nomenclatures: { steps, showPromoOffer },
  } = useApplicationState();

  const classes = useStyles(showPromoOffer);

  const [showOfferDialog, setShowOfferDialog] = React.useState(false);

  const progressBarSteps = steps.filter((step) => step.partOfStepsFlow);
  const stepsCount = progressBarSteps.length;
  const currentStepNameEN = activeStep || (stepsCount && progressBarSteps[0].nameEN);

  const handleOfferDialogClose = () => {
    setShowOfferDialog(false);
  };

  const handleOfferPreview = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowOfferDialog(true);
  };

  return (
    <div className={classes.root}>
      {!requestCannotContinue &&
      stepsCount &&
      currentStepNameEN === progressBarSteps[0].nameEN &&
      !isFromBranch &&
      !showEntryStep ? (
        <Grid item xs={12}>
          <Box className={classes.ourOfferCard} onClick={handleOfferPreview}>
            {showPromoOffer ? (
              <>
                <div>
                  <Typography className={classes.ourOfferText}>
                    {t('client-data-step.offer-promo-card-title')}
                  </Typography>
                  <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#FFF' }}>
                    {t('client-data-step.offer-promo-card-campaign')}
                  </Typography>
                </div>
                <div>
                  <PromoCampaign />
                </div>
              </>
            ) : (
              <>
                <span className={classes.ourOfferText}>
                  {t('client-data-step.offer-card-title')}
                </span>
                <div className={classes.ourOfferButton}>
                  <IconButton>
                    <img src={OurOfferButton} alt='our-offer-button' />
                  </IconButton>
                </div>
              </>
            )}
          </Box>
        </Grid>
      ) : null}

      <OfferModal
        open={showOfferDialog}
        handleClose={handleOfferDialogClose}
        title={t('personal-data-consent.title')}
      />
    </div>
  );
}

export default OfferCard;
